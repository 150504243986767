import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMount } from "react-use"

import { usePaginationParams } from "@/components/pagination/pagination-state"

import { useFetchCallHistory } from "./call-history-schemas"
import { useCallHistorySearch } from "./call-history-search"
import { CallHistorySearchBar } from "./call-history-search-bar"
import { CallHistoryTable } from "./call-history-table"

export function CallReportsPage() {
  const now = useDefaultEndDate()
  if (!now) return null

  return <CallReports currentDate={now} />
}

export function CallReports({ currentDate }: { currentDate: Date }) {
  const { t } = useTranslation()
  const defaultPageSize = 20
  const { page, pageSize } = usePaginationParams(defaultPageSize)
  const { onChange, searchOptions } = useCallHistorySearch()
  const { date, search_query, statuses } = searchOptions
  const { data, isLoading } = useFetchCallHistory({
    date: { range: date.range, to: date.to || currentDate },
    pageSize,
    page,
    statuses,
    search_query,
  })

  const callReports = data?.data.call_reports || []
  const total = data?.data.total || 0

  return (
    <main className="flex flex-col gap-y-8 px-8 pt-10">
      <h1 className="font-h1-bold" data-unmask>
        {t("callHistory.heading")}
      </h1>
      <CallHistorySearchBar onChange={onChange} searchOptions={searchOptions} />
      <CallHistoryTable
        callReports={callReports}
        isLoading={isLoading}
        total={total}
        page={page}
        pageSize={pageSize}
      />
    </main>
  )
}

/**
 * Whenever the page is re-mounted (user going offline or navigating), reset the default "end date"
 * to ensure search results include latest data
 * TODO: the API should accept empty `to_date` to make queries with only a start date
 **/
function useDefaultEndDate() {
  const [date, setDate] = useState<Date | undefined>()

  useMount(() => {
    const now = new Date()
    setDate(now)
  })

  return date
}
