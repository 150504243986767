import { forwardRef, PropsWithChildren, useState } from "react"
import { useTranslation } from "react-i18next"
import { ArrowLeftFromLineIcon, ArrowRightFromLineIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  Sheet,
  SheetClose,
  SheetTrigger,
  SidePanelContent,
} from "@/components/ui/side-panel-sheet"
import { cn } from "@/helpers/classNames"

/**
 * Data agnostic `Sheet` component, to be used in the actual Panel and when testing in Storybook
 */
export function AgentMetricsSheet({
  children,
  onOpen,
}: PropsWithChildren<{ onOpen: (value: boolean) => void }>) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpenChange = (value: boolean) => {
    if (onOpen) onOpen(value)
    setOpen(value)
  }

  return (
    <Sheet open={open} onOpenChange={handleOpenChange} modal={false}>
      <SheetTrigger asChild>
        {!open && (
          <OpenCloseButton
            className="left-0 top-1/2 translate-y-[-200px]"
            icon={
              <ArrowRightFromLineIcon className="size-4 text-neutral-500" />
            }
            text={t("sidePanelMetrics.open")}
          />
        )}
      </SheetTrigger>
      <SidePanelContent className="w-[104px]">
        {children}
        <SheetClose asChild>
          <OpenCloseButton
            className="absolute -right-10 top-1/2 translate-y-[-200px] rounded-l-none rounded-r-xl border-l"
            text={t("global.button.close")}
            icon={<ArrowLeftFromLineIcon className="size-4 text-neutral-500" />}
          />
        </SheetClose>
      </SidePanelContent>
    </Sheet>
  )
}

type OpenCloseButtonProps = {
  className: string
  icon: React.ReactNode
  text: string
}

const OpenCloseButton = forwardRef<HTMLButtonElement, OpenCloseButtonProps>(
  ({ className, icon, text, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="outline"
        className={cn(
          "agent-metrics-panel-button", // to target with a custom media query in main.css
          "absolute flex h-auto min-h-[180px] w-[40px] flex-col rounded-none rounded-r-xl border-l-0 p-0",
          className,
        )}
        {...props}
      >
        {icon}
        <VerticalText text={text} />
      </Button>
    )
  },
)

OpenCloseButton.displayName = "OpenCloseButton"

function VerticalText({ text }: { text: string }) {
  return (
    <div className="text-sm font-normal">
      {text.split("").map((letter) => (
        <div key={letter}>{letter}</div>
      ))}
    </div>
  )
}
