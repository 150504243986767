import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useAtomValue } from "jotai"

import { config } from "@/config/index"
import {
  agentStatusTypeAtom,
  campaignNameAtom,
  caseIdAtom,
  currentContactIdAtom,
  phoneNumberAtom,
  UndocumentedAgentStateType,
} from "@/helpers/atoms"
import { hasValue } from "@/helpers/typeguards"
import { caseIdRegexp } from "@/helpers/zodSchemas"
import { useQueryAPI } from "@/hooks/query-api"
import { useReactQueryOptions } from "@/hooks/reactQueryOptions"
import { useManualCallAtom } from "@/hooks/useManualCallAtom"

import { getCaseQueryParams } from "./case_params"
import * as value from "./value"

type Initializing = {
  current: "initializing"
}

type AvailableMode = {
  current: "available"
}

type RequestWork = {
  current: "request-work"
}

type BusyMode = {
  current: "busy"
  state: {
    currentCase: value.Decoder.CaseAndActivityFeeds | null
    isLoading: boolean
    studentProfile: value.Decoder.StudentProfile | null
  }
}

type OfflineMode = {
  current: "offline"
  state: {
    currentCase: value.Decoder.CaseAndActivityFeeds | null
  }
}

type ResetMode = {
  current: "reset"
  state: {
    currentCase: value.Decoder.CaseAndActivityFeeds | null
    errorContext?: "queue-refill-error" | "queue-refill-rate-limit-error"
  }
}

export type AgentDisplayMode =
  | AvailableMode
  | BusyMode
  | Initializing
  | OfflineMode
  | RequestWork
  | ResetMode

interface CustomPanelHookType {
  displayMode: AgentDisplayMode
  setDisplayMode: Dispatch<SetStateAction<AgentDisplayMode>>
}

const useCustomPanelHook = (): CustomPanelHookType => {
  const { retry } = useReactQueryOptions()

  useManualCallAtom()
  const agentStatusType = useAtomValue(agentStatusTypeAtom)
  const currentContactId = useAtomValue(currentContactIdAtom)

  const caseId = useAtomValue(caseIdAtom)
  const phoneNumber = useAtomValue(phoneNumberAtom)
  const campaignName = useAtomValue(campaignNameAtom)

  const [displayMode, setDisplayMode] = useState<AgentDisplayMode>({
    current: "initializing",
  })

  const queueIsEmptyTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const loadingTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)

  const studentId = useMemo(() => {
    if (!caseId) {
      return null
    }

    const params = caseIdRegexp.exec(caseId)
    const studentId = params?.groups?.studentId

    return studentId ?? null
  }, [caseId])

  const isDataLoading =
    displayMode.current === "busy" && displayMode.state.isLoading

  const caseQueryParams = getCaseQueryParams({
    campaignName,
    caseId,
    phoneNumber,
  })

  const caseQueryOptions = {
    initialData: null,
    enabled: hasValue(caseQueryParams),
    refetchOnWindowFocus: false,
    retry,
  }

  const studentProfileQueryOptions = {
    initialData: null,
    enabled: hasValue(studentId) || hasValue(phoneNumber),
    refetchOnWindowFocus: false,
    retry,
  }

  const caseQuery = useQueryAPI({
    queryKey: ["currentCase", caseId, phoneNumber],
    apiEndPoint: `/case`,
    params: caseQueryParams,
    options: caseQueryOptions,
    schema: value.Decoder.caseInformationSchema,
  })

  const studentProfileQuery = useQueryAPI({
    queryKey: ["studentProfile", studentId, phoneNumber],
    apiEndPoint: `/students`,
    params: {
      student_id: studentId ?? undefined,
      phone_number: phoneNumber ?? undefined,
    },
    schema: value.Decoder.customerProfileSchema,
    options: studentProfileQueryOptions,
  })

  const currentCase = caseQuery.data?.data ?? null
  const currentCaseId = caseQuery.data?.data.case._id ?? null
  const isCaseLoading = caseQuery.isLoading || caseQuery.isFetching

  const studentProfile = studentProfileQuery.data?.data ?? null
  const studentProfileId = studentProfile?.student_id ?? null
  const isStudentProfileLoading =
    studentProfileQuery.isLoading || studentProfileQuery.isFetching

  const isLoading = isStudentProfileLoading || isCaseLoading

  useEffect(
    () => {
      switch (agentStatusType) {
        case connect.AgentStateType.ROUTABLE:
          // hardcoded isLoading = true; when contact ends the inbound call and there is no more contact id
          // we must show the loading screen
          setDisplayMode(() => ({
            current: "busy",
            state: {
              isLoading: currentContactId ? isLoading : true,
              studentProfile,
              currentCase,
            },
          }))
          break

        // The busy agent status type seems appear throughout the outbound call
        case UndocumentedAgentStateType.SYSTEM:
        case UndocumentedAgentStateType.ERROR:
          setDisplayMode(() => ({
            current: "busy",
            state: { isLoading, studentProfile, currentCase },
          }))
          break

        case connect.AgentStateType.OFFLINE:
          setDisplayMode(() => ({
            current: "offline",
            state: { currentCase },
          }))
          break

        // connect.AgentStateType.INIT
        // connect.AgentStateType.NOT_ROUTABLE
        default:
          setDisplayMode(() => ({ current: "initializing" }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      agentStatusType,
      currentContactId,
      isLoading,
      // Replace object references with an unique primitive on the object.
      // case statuses does not change, it's a hardcoded list of statuses. Once non null, should be fine
      currentCaseId,
      studentProfileId,
    ],
  )

  // Start a timer to trigger requesting more work if the queue is empty
  useEffect(() => {
    if (agentStatusType === connect.AgentStateType.ROUTABLE) {
      queueIsEmptyTimeoutRef.current = setTimeout(() => {
        if (!currentContactId) {
          setDisplayMode(() => ({ current: "request-work" }))

          // Stop the timer that should show the panel reset button when
          // the data for the current contact takes too long to load
          clearTimeout(loadingTimeoutRef.current)
        }
      }, config.emptyQueueOffset)
    }

    return () => clearTimeout(queueIsEmptyTimeoutRef.current)
  }, [agentStatusType, currentContactId])

  // Start a timer to show a reset button if loading of the data for the current contact takes too long
  useEffect(() => {
    loadingTimeoutRef.current = setTimeout(() => {
      if (isDataLoading) {
        setDisplayMode(() => ({ current: "reset", state: { currentCase } }))

        // Stop the timer that should trigger the request for more work if the queue is empty
        clearTimeout(queueIsEmptyTimeoutRef.current)
      }
    }, config.resetPanelTimeout)

    return () => clearTimeout(loadingTimeoutRef.current)
  }, [isDataLoading, currentCase])

  return { displayMode, setDisplayMode }
}

export { useCustomPanelHook }
