import { Controller, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"

import * as callViewValue from "@/components/CallView/value"
import TextArea from "@/components/core/TextArea"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { SelectWithOptions } from "@/components/ui/select"
import { cn } from "@/helpers/classNames"
import { CallReportType } from "@/hooks/useSendCallReport"

import { DateAndTimePicker } from "../ui/date-and-time-picker"
import { CallReportOption } from "./call-report-options"
import { useCallReportStatelessForm } from "./call-report-stateless-form-hook"

const styles = {
  acwContainer: (className: string | undefined) =>
    cn(["col-span-full flex flex-col gap-y-6 lg:col-start-2", className]),
  title: "font-h3-regular text-neutral-600",
  formSplitLine: "flex gap-x-4 gap-y-2 flex-wrap",
  selectContainer: "w-48 max-w-1/2 flex-grow",
}

export interface CallReportProps {
  availableCallReportOptions: CallReportOption[]
  callReportType: CallReportType
  className?: string
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  inModal?: boolean
  isLoadingAgent: boolean
  isNewItem: boolean
  onSave: (data: callViewValue.Decoder.DecoderType) => void
  title: string | null
}

/**
 * Extract AWS Connect logic, keeping only the form logic, to be able to test this form in isolation
 */
export function CallReportStatelessForm({
  availableCallReportOptions,
  callReportType,
  className,
  form,
  inModal = false,
  isLoadingAgent,
  isNewItem,
  onSave,
  title,
}: CallReportProps) {
  const { t } = useTranslation()

  const {
    clearErrors,
    formState: { errors },
  } = form

  const {
    dateConstraints,
    handleSubmit,
    isDatePickerEnabled,
    isDisabled,
    options,
    validDate,
    validateCallScheduling,
  } = useCallReportStatelessForm({
    availableCallReportOptions,
    form,
    onSave,
    callReportType,
  })

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className={styles.acwContainer(className)}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <FormField
          control={form.control}
          rules={{ required: true }}
          name="status"
          label={t("callResult.status")}
          disabled={isDisabled}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("callResult.status")}</FormLabel>
              <FormControl>
                <SelectWithOptions
                  options={options.status}
                  {...field}
                  placeholder={t("dashboard.selectOptions.selectPlaceholder")}
                  size="lg"
                  unmask
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          rules={{ required: Boolean(options.reason.length) }}
          name="reason"
          label={t("callResult.reason")}
          disabled={isDisabled}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("callResult.reason")}</FormLabel>
              <FormControl>
                <SelectWithOptions
                  options={options.reason}
                  {...field}
                  placeholder={t("dashboard.selectOptions.selectPlaceholder")}
                  size="lg"
                  unmask
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          label={t("callResult.scheduleCall")}
          rules={{
            required: isDatePickerEnabled,
            validate: {
              wrongDate: (value) => validDate(value, isDatePickerEnabled),
              validStartDateTime: (value) =>
                validateCallScheduling(value, isDatePickerEnabled),
            },
          }}
          name="scheduledCallDate"
          disabled={isDisabled}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("callResult.scheduleCall")}</FormLabel>
              <FormControl>
                <DateAndTimePicker
                  {...field}
                  minDate={dateConstraints.minDate}
                  maxDate={dateConstraints.maxDate}
                  isDisabled={!isDatePickerEnabled}
                  className="flex w-full text-sm"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Controller
          name="memo"
          control={form.control}
          render={({ field: { name, onBlur, onChange, ref, value } }) => {
            return (
              <TextArea
                name={name}
                placeholder="callResult.placeholder"
                orientation="vertical"
                label="callResult.memo"
                onBlur={onBlur}
                onChange={onChange}
                errors={errors}
                clearErrors={clearErrors}
                forwardedRef={ref}
                value={value}
                maxLength={500}
                disabled={isDisabled}
              />
            )
          }}
        />
        {!inModal && (
          <Button
            type="submit"
            className="w-32"
            isDisabled={isLoadingAgent || isDisabled}
            loadingMessage={t("global.button.savingReport")}
          >
            {isNewItem ? t("global.button.save") : t("global.button.update")}
          </Button>
        )}
      </form>
    </Form>
  )
}
