import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { PencilIcon } from "lucide-react"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import { PaginationState } from "@/components/pagination/pagination-state"
import { EmptyValue, TableLink } from "@/components/table-helpers"
import { Button } from "@/components/ui/button"

import { getCampaignStatus } from "../api/campaigns"
import { extractMetricDescription } from "../api/metrics"
import { Campaign, CampaignMetricsMeta } from "../campaigns-types"
import { CampaignBadge } from "./campaign-badges"
import { CampaignRowDropdownMenu } from "./campaigns-actions"

type Props = PaginationState & {
  campaigns: Campaign[]
  hasWriteAccess: boolean
  isLoading?: boolean
  metricsMeta?: CampaignMetricsMeta
  onEdit?: (campaign_id: string) => void
}

export function CampaignsTable({
  campaigns,
  hasWriteAccess,
  metricsMeta,
  onEdit,
  ...rest
}: Props) {
  const columnGroups = useColumns({ hasWriteAccess, metricsMeta, onEdit })

  return (
    <DashboardTable
      columnGroups={columnGroups}
      items={campaigns}
      getItemId={(campaign) => campaign.campaign_id}
      {...rest}
    />
  )
}

export function LoadingCampaignsTable({
  hasWriteAccess,
}: Pick<Props, "hasWriteAccess">) {
  const columnGroups = useColumns({ hasWriteAccess })

  return (
    <DashboardTable
      columnGroups={columnGroups}
      items={[]}
      isLoading
      getItemId={(campaign) => campaign.campaign_id}
    />
  )
}

function useColumns({
  hasWriteAccess,
  metricsMeta,
  onEdit,
}: Pick<Props, "hasWriteAccess" | "metricsMeta" | "onEdit">) {
  const { t } = useTranslation()

  const getTooltip = (id: string) => {
    return metricsMeta ? extractMetricDescription(metricsMeta, id) : undefined
  }

  const defaultColumnGroups: ColumnGroupDef<Campaign>[] = [
    {
      header: t("campaigns.list.name"),
      cols: [
        {
          id: "campaign_id",
          cell: (campaign) => (
            <TableLink
              to={`/campaigns/${campaign.campaign_id}`}
              className="font-bold"
              data-unmask
            >
              {campaign.campaign_id}
            </TableLink>
          ),
        },
      ],
    },
    {
      header: t("campaigns.list.studentCount"),
      headerTooltip: getTooltip("TotalStudentsInCampaign"),
      cols: [
        {
          id: "TotalStudentsInCampaign",
          cell: (campaign) => (
            <PeopleCount
              count={campaign.metrics?.TotalStudentsInCampaign || null}
            />
          ),
        },
      ],
    },
    {
      header: t("campaigns.list.studentsToBeCalled"),
      headerTooltip: getTooltip("TotalStudentsCanBeCalledToday"),
      cols: [
        {
          id: "TotalStudentsCanBeCalledToday",
          cell: (campaign) => (
            <PeopleCount
              count={campaign.metrics?.TotalStudentsCanBeCalledToday || null}
            />
          ),
        },
      ],
    },
    {
      header: t("campaigns.list.status"),
      cols: [
        {
          id: "status",
          cell: (campaign) => (
            <CampaignBadge status={getCampaignStatus(campaign)} />
          ),
        },
      ],
    },
  ]

  const extraColumns: ColumnGroupDef<Campaign>[] = [
    {
      header: "",
      cols: [
        {
          className: "w-[100px]",
          id: "edit",
          cell: (campaign) => (
            <Link to={`/campaigns/${campaign.campaign_id}/edit`}>
              <Button size="sm" variant="outline">
                <PencilIcon className="size-4" />
                {t("global.button.edit")}
              </Button>
            </Link>
          ),
        },
      ],
    },
    {
      header: "",
      cols: [
        {
          className: "w-[50px]",
          id: "actions",
          cell: (campaign) =>
            onEdit ? (
              <CampaignRowDropdownMenu campaign={campaign} onEdit={onEdit} />
            ) : (
              <EmptyValue />
            ),
        },
      ],
    },
  ]

  const columnGroups = hasWriteAccess
    ? [...defaultColumnGroups, ...extraColumns]
    : defaultColumnGroups

  return columnGroups
}

function PeopleCount({ count }: { count: number | null }) {
  const { t } = useTranslation()

  if (!count) return <span className="text-neutral-500">—</span>

  return <>{t("global.peopleCount", { count })}</>
}
