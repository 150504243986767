import { DisplayMode } from "@/components/CallPanel/hook"
import { config } from "@/config"

import { useIdleAgent } from "./idle-agent"
import { ProcessingTimeDebugPanel } from "./processing-time-debug-panel"
import { useTrackProcessingTime } from "./track-processing-time"

const debugMode = import.meta.env.VITE_DEBUG_TIME === "1" // start the app with `VITE_DEBUG_TIME=1 pnpm dev` when debugging

/**
 * To be included from both Automatic and Manual Call View to track the Processing time
 * showing a panel in debug mode
 * */
export function ProcessingTimeWrapper({
  contactId,
  displayMode,
}: {
  contactId: string
  displayMode: DisplayMode
}) {
  useTrackProcessingTime(contactId)

  const isCalling = getIsCalling(displayMode)
  const idleTimeout = config.agentIdleMinutes * 60 * 1000

  return (
    <>
      {!isCalling && (
        <AgentIdleDetection contactId={contactId} idleTimeout={idleTimeout} />
      )}
      {debugMode && (
        <ProcessingTimeDebugPanel
          contactId={contactId}
          displayMode={displayMode}
        />
      )}
    </>
  )
}

/* To be able to call the hook conditionally, taking advantage of unmounting to reset timer */
function AgentIdleDetection({
  contactId,
  idleTimeout,
}: {
  contactId: string
  idleTimeout: number
}) {
  useIdleAgent({ contactId, idleTimeout })

  return null
}

const inCallModes: DisplayMode["current"][] = [
  "connecting",
  "connected-call",
] as const

function getIsCalling(displayMode: DisplayMode) {
  return inCallModes.includes(displayMode.current)
}
