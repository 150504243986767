import { Button } from "@/components/ui/button"
import {
  DialogBodyError,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog"
import { useI18n } from "@/helpers/i18n"

type Props = {
  close: (value: boolean) => void
  idleTimeout: number
}

export function DialogIdle({ close, idleTimeout }: Props) {
  const { t, tAsArray } = useI18n()

  const minutes = Math.round(idleTimeout / 60_000)

  return (
    <DialogContent isPending className="pt-8">
      <DialogBodyError>
        <div className="space-y-4">
          <DialogTitle>{t("callPanel.idle.heading")}</DialogTitle>
          {tAsArray("callPanel.idle.body", { minutes }).map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
      </DialogBodyError>
      <DialogFooter>
        <Button onClick={() => close(true)}>
          {t("callPanel.idle.button")}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
