import { addDays } from "date-fns"
import { z } from "zod"

import {
  caseIdSchema,
  datetimeSchema,
  idSchema,
  optionalDateTimeSchema,
} from "@/helpers/zodSchemas"

namespace Encoder {
  export const defaultValues = (rawCallReport: unknown) => {
    try {
      return Decoder.schema.parse(rawCallReport)
    } catch (error) {
      return { status: "", reason: "", scheduledCallDate: null, memo: "" }
    }
  }
}

namespace Decoder {
  export const schema = z
    .object({
      status: z.string(),
      reason: z.string(),
      scheduledCallDate: datetimeSchema.nullable(),
      memo: z.string(),
      updated_at: optionalDateTimeSchema,
    })
    .refine(
      ({ scheduledCallDate }) => {
        if (scheduledCallDate && scheduledCallDate < new Date()) {
          return false
        }

        return true
      },
      { message: "Scheduled call date is too far in the past" },
    )
    .refine(
      ({ scheduledCallDate }) => {
        if (scheduledCallDate && scheduledCallDate > addDays(new Date(), 6)) {
          return false
        }

        return true
      },
      { message: "Scheduled call date is too far in the future" },
    )

  export type DecoderType = z.infer<typeof schema>

  export const saveReportSchema = z.object({
    data: z.object({
      case_id: caseIdSchema,
      is_campaign_no_call: z.boolean(),
      is_global_no_call: z.boolean().nullable(),
      new_contact_id: idSchema.nullable(),
    }),
    reqId: idSchema,
    success: z.string(),
  })

  export type SaveReportResult = z.infer<typeof saveReportSchema>
}

export { Decoder, Encoder }
