import { Fragment, FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"
import { EventStatusPill } from "@/components/status-pill"
import { formatDistanceToDate } from "@/helpers/dateFormat"
import { formatStringValue as formatValue } from "@/helpers/valueFormat"
import { useFormValidation } from "@/hooks/formValidation"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

const styles = {
  container:
    "bg-neutral-100 border border-neutral-300 rounded px-4 py-2 shadow-card flex flex-col gap-y-2 font-body-medium-regular text-neutral-700",
  reason: "px-4 py-2 rounded border border-neutral-300 my-1",
  footerContainer: "flex gap-x-2 items-center my-1 flex-wrap",
  textWrapper: "flex gap-x-1 items-center",
  textContent: "font-body-medium-bold",
}

interface Props {
  event: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType
}

const HistoryItemCard: FunctionComponent<Props> = ({ event }) => {
  const { t } = useTranslation()
  const { isKanji } = useFormValidation()

  const firstName = event.status_event?.first_name ?? ""
  const lastName = event.status_event?.last_name ?? ""

  const agentName = isKanji(lastName || firstName)
    ? `${lastName}${firstName}`
    : `${firstName} ${lastName}`

  return (
    <div className={styles.container}>
      <span data-unmask>
        {formatDistanceToDate(event.status_event?.created_at)}
      </span>
      {event.status_event && (
        <div>
          <EventStatusPill value={event.status_event.event_name} />
        </div>
      )}
      {event.comment?.comment && (
        <div className={styles.reason} data-unmask>
          {formatValue(event.comment?.comment)
            .split("\n")
            .map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
        </div>
      )}
      <div className={styles.footerContainer}>
        <div className={styles.textWrapper}>
          <span data-unmask>{t("caseHistory.agent")}:</span>
          <span className={styles.textContent}>
            {formatValue(agentName.trim())}
          </span>
        </div>
        <Icon name="dot" size="xxsm" className={"text-neutral-500"} />
        <div className={styles.textWrapper}>
          <span data-unmask>{t("caseHistory.campaign")}:</span>
          <span className={styles.textContent} data-unmask>
            {formatValue(event.campaign_id)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default HistoryItemCard
