import { useState } from "react"
import { useSetAtom } from "jotai"

import { inManualCallAtom } from "@/helpers/atoms"
import { useCallDuration } from "@/hooks/callDuration"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useToast } from "@/hooks/toastHook"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { CallReportType } from "@/hooks/useSendCallReport"
import { CaseWithActivityFeed } from "@/pages/Campaigns/student-details/student-details-api"
import { contactStorageService } from "@/services/localStorageService"

import { useModal } from "../core/Root/modal-root"
import {
  DialogConfirmBackToCallMode,
  StatusUpdateModal,
} from "./callpanel-modals"
import { useConnectedCallButtonsHook } from "./ConnectedCallButtons/hook"
import { useErrorButtonsHook } from "./ErrorButtons/hook"
import { DisplayMode } from "./hook"

export function useCallPanelActions({
  displayMode,
  phoneNumber,
  setHasCalled,
  switchCallReportMode,
}: {
  displayMode: DisplayMode
  phoneNumber: string | null
  setHasCalled: (hasCalled: boolean) => void
  switchCallReportMode: (val: CallReportType) => void
}) {
  const {
    answerPhoneCall,
    callPhoneNumber,
    closeContact,
    closeTask,
    hangUpPhoneCall,
    isMuted,
    isOnHold,
    manualCallPhoneNumber,
    rejectCall,
    toggleHoldCall,
    toggleMuteCall,
  } = useConnectActionsHook()
  const { clearTimestampsInLocalStorage, closeContactWithFallback } =
    useErrorButtonsHook()

  const setInManualCall = useSetAtom(inManualCallAtom)

  const { error: toastError } = useToast()
  const log = useCallPanelLogger()
  const { showModal } = useModal()

  const [isSkippedCall, setSkippedCall] = useState(false)

  useConnectedCallButtonsHook({
    setHasCalled,
    isInboundCall:
      displayMode.current === "connecting" && displayMode.state.isInboundCall,
  })

  const { endTimer, startTimer } = useCallDuration()

  async function acceptInboundCall() {
    await wrapAction(async () => {
      await answerPhoneCall()
      startTimer()
    }, "errors.callPanel.answerCallError")
  }

  async function rejectInboundCall() {
    await wrapAction(async () => {
      await rejectCall()
      setSkippedCall(true)
    }, "errors.callPanel.skipCallError")
  }

  async function endCall() {
    await wrapAction(async () => {
      await hangUpPhoneCall()
      endTimer()
    }, "errors.callPanel.endCallError")
  }

  async function toggleHold() {
    await wrapAction(async () => {
      await toggleHoldCall()
    }, `errors.callPanel.${isOnHold ? "resumeCallError" : "holdCallError"}`)
  }

  async function toggleMute() {
    await wrapAction(async () => {
      toggleMuteCall()
    }, `errors.callPanel.${isMuted ? "unmuteCallError" : "muteCallError"}`)
  }

  async function call() {
    await wrapAction(async () => {
      if (!phoneNumber) {
        throw new Error("No phone number found")
      }
      await callPhoneNumber(phoneNumber)
      startTimer()
    }, "errors.callPanel.callError")
  }

  async function manualCall(queueARN: string, caseData: CaseWithActivityFeed) {
    wrapAction(async () => {
      if (!phoneNumber) {
        throw new Error("No phone number found")
      }
      if (!queueARN) {
        throw new Error("No queueARN provided")
      }

      // Turn a boolean true when the agent initiates a manual call;
      // use this boolean to decide in the iframe contact.onDestroy event if whe need to prevent the automatic
      // erasing of the contact state in local storage; e.g. if the manual call results in Pseudo ACW state
      // we need to preserve the state until the agent posts a call report
      setInManualCall(true)
      contactStorageService.setManualCallCase(caseData)
      await manualCallPhoneNumber(phoneNumber, queueARN)
      startTimer()
    }, "errors.callPanel.callError")
  }

  async function addStatusInModal(caseInfo: CaseWithActivityFeed) {
    await showModal<boolean>((close) => (
      <StatusUpdateModal onClose={close} studentCase={caseInfo} />
    ))
    contactStorageService.removeCallReport()
  }

  function skip() {
    switchCallReportMode("automatic-pre-call")
  }

  async function backToCallMode() {
    const confirm = await showModal<boolean>((close) => (
      <DialogConfirmBackToCallMode close={close} />
    ))
    if (confirm) switchCallReportMode("disabled")
  }

  async function endTaskError() {
    await wrapAction(async () => {
      if (displayMode.current !== "error") return
      const { message } = displayMode.state

      clearTimestampsInLocalStorage()
      closeTaskError(message)
    }, "errors.callPanel.nextContactError")
  }

  async function closeTaskError(errorMessage: string) {
    switch (errorMessage) {
      case "missed_call":
      case "missed_call_agent_state":
        await closeContact()
        break

      case "missed_task":
      case "rejected_task":
        await closeTask()
        log.warn(
          "Skipped task with impossible state - rejected task not closed.",
        )
        break

      case "internal_error":
        await closeContactWithFallback()
        break
      default:
        log.warn(
          `Conditional for message ${errorMessage} is not implemented in "closeTaskError" function`,
        )
    }
  }

  /** Wrap actions with the same error handling, showing a toast message with a given error message */
  async function wrapAction(action: () => Promise<void>, errorMessage: string) {
    try {
      await action()
    } catch (err) {
      if (err instanceof Error) {
        log.error(err)
      }
      toastError(errorMessage)
    }
  }

  return {
    acceptInboundCall,
    backToCallMode,
    call,
    endCall,
    endTaskError,
    isMuted,
    isOnHold,
    manualCall,
    rejectInboundCall,
    skip,
    addStatusInModal,
    toggleHold,
    toggleMute,
    isSkippedCall,
  }
}
