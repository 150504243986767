/* eslint-disable react-refresh/only-export-components */
import * as React from "react"
import { useTranslation } from "react-i18next"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { LoaderCircle } from "lucide-react"

import { cn } from "@/helpers/classNames"

const buttonVariants = cva(
  [
    "inline-flex items-center justify-center whitespace-nowrap rounded ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    "gap-2", // icon and text gap
  ],
  {
    variants: {
      variant: {
        default:
          "font-button-medium bg-primary-300 text-neutral-100 hover:bg-primary-200",
        destructive:
          "font-button-medium bg-danger-300 text-neutral-100 hover:bg-danger-200",
        success:
          "font-button-medium bg-success-400 text-neutral-100 hover:bg-success-300",
        outline:
          "font-button-medium border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        soft: "font-button-medium bg-transparent border border-primary-200 text-primary-300 hover:bg-accent hover:text-accent-foreground",
        secondary:
          "font-button-medium bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost:
          "font-button-medium hover:bg-accent hover:text-accent-foreground",
        link: "font-medium text-primary-300 underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        xs: "h-4",
        sm: "h-9 rounded-md px-3 text-sm",
        lg: "h-11 rounded-md px-8",
        icon: "size-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  loadingMessage?: string
}

/**
 * Button from [shadcn/ui](https://ui.shadcn.com/docs/components/button), customize the appearance with the `variant` prop
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      isDisabled,
      isLoading,
      loadingMessage,
      size,
      variant,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button"

    const { t } = useTranslation()

    const defaultLoadingMessage = t("global.button.loading")

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isDisabled || isLoading}
        {...props}
        {...{ "data-unmask": true }}
      >
        {isLoading ? (
          <>
            <LoaderCircle className="animate-spin" />
            {loadingMessage ?? defaultLoadingMessage}
          </>
        ) : (
          <>{children}</>
        )}
      </Comp>
    )
  },
)
Button.displayName = "Button"

export { Button, buttonVariants }
