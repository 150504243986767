import { PropsWithChildren } from "react"

import { cn } from "@/helpers/classNames"
import { useCallStatusLabel } from "@/hooks/call-statuses-context"
import { Status, statusMap } from "@/models/cases"

type EventStatusPillProps = {
  className?: string
  value: Status
}

type CurrentStatusPillProps = {
  className?: string
  current: Status
  latestActionTaken: Status | null
}

/** Pill to show a given status from an activity feed */
export function EventStatusPill({ className, value }: EventStatusPillProps) {
  const getLabel = useCallStatusLabel()

  return (
    <Pill className={cn(getPillBgColor(value), className)}>
      {getLabel(value)}
    </Pill>
  )
}

/** Specific Pill component to show the current status of a student, handling the `Leave as it` case */
export function CurrentStatusPill({
  className,
  current,
  latestActionTaken,
}: CurrentStatusPillProps) {
  const getLabel = useCallStatusLabel()

  return (
    <Pill
      className={cn(getPillBgColor(latestActionTaken || current), className)}
      data-unmask
    >
      {current === latestActionTaken || !latestActionTaken ? (
        getLabel(current)
      ) : (
        <>
          {getLabel(latestActionTaken)}
          <span className="ml-2 font-normal">({getLabel(current)})</span>
        </>
      )}
    </Pill>
  )
}

function Pill({
  children,
  className,
}: PropsWithChildren<{ className: string }>) {
  return (
    <span
      className={cn(
        "font-label-medium block max-w-fit rounded-full px-4 py-1 font-bold text-neutral-600",
        className,
      )}
      data-unmask
    >
      {children}
    </span>
  )
}

function getPillBgColor(status: Status) {
  switch (status) {
    case statusMap.TentativeEntry:
    case statusMap.ConfirmedEntry:
    case statusMap.ConfirmedEntryByAgent:
      return "bg-success-100"
    case statusMap.InboundMissedCall:
    case statusMap.CallLaterAtGivenTime:
    case statusMap.CallAgainUnsuitableDates:
    case statusMap.CallerTemporarilyUnavailable:
    case statusMap.SendEmailCallLater:
      return "bg-warning-200"
    case statusMap.GlobalNoCall:
    case statusMap.RejectInstantly:
    case statusMap.RejectAfterPitch:
    case statusMap.RejectUnsuitableDates:
    case statusMap.ConsecutiveMissedCalls:
    case statusMap.Cancelled:
    case statusMap.AlreadyRegistered:
      return "bg-danger-100"
    default:
      return "bg-primary-100"
  }
}
