import { useTranslation } from "react-i18next"

import Icon, { IconName } from "@/components/Icon"

import { DisplayMode } from "./hook"

type Props = {
  displayMode: DisplayMode
}

export function CallPanelStatusIcon({ displayMode }: Props) {
  const { t } = useTranslation()

  const { status, statusIconName } = getStatusAndIcon({
    displayMode,
  })

  return (
    <>
      {statusIconName && <Icon name={statusIconName} size="xsm" />}
      {status && (
        <p className="font-body-medium-bold" data-unmask>
          {t(status)}
        </p>
      )}
    </>
  )
}

function getStatusAndIcon({ displayMode }: Props) {
  const baseTranslationKey = "callPanel.status."

  let translationKey = ""
  let statusIconName: IconName | undefined = undefined

  switch (displayMode.current) {
    case "connecting":
      translationKey = `${baseTranslationKey}connecting`
      statusIconName = "dot-warning"
      break

    case "connected-task":
      if (displayMode.state.callReportType === "automatic-pre-call") {
        translationKey = "callPanel.report.title"
        statusIconName = "dot-primary"
      }
      break

    case "connected-call":
      translationKey = `${baseTranslationKey}connected`
      statusIconName = "dot-success"
      break

    case "acw":
      if (displayMode.state.contactType === connect.ContactType.TASK) {
        break
      }

      translationKey = `${baseTranslationKey}acw`
      statusIconName = "dot-primary"
      break

    case "error":
      const errorKey = getErrorTranslationKey(displayMode) // eslint-disable-line no-case-declarations
      translationKey = errorKey ? errorKey : `${baseTranslationKey}error`
      statusIconName = "dot-error"
      break
  }

  // By default show no status message in the initializing state
  return {
    status: translationKey,
    statusIconName,
  }
}

function getErrorTranslationKey(displayMode: DisplayMode): string | undefined {
  if (displayMode.current !== "error") {
    return
  }

  const baseTranslationKey = "callPanel.status."
  const isMissedTask = displayMode.state.message === "missed_task"
  const isMissedCall = displayMode.state.message === "missed_call"
  const isMissedCallAgentState =
    displayMode.state.message === "missed_call_agent_state" // e.g. due to networking error
  // or due to a call getting 'stuck' for >30 seconds e.g. due to lack of microphone access.
  const isRejected = displayMode.state.message === "rejected_call"
  const isSkipped = displayMode.state.message === "rejected_task"

  if (isMissedTask) {
    return `${baseTranslationKey}missedTask`
  }

  if (isMissedCall) {
    return `${baseTranslationKey}missedCall`
  }

  if (isMissedCallAgentState) {
    return `${baseTranslationKey}missedCallAgentState`
  }

  if (isSkipped) {
    return `${baseTranslationKey}skipped`
  }

  if (isRejected) {
    return `${baseTranslationKey}rejectedCall`
  }

  return
}
