import { useEffect } from "react"
import { UseFormReturn, useWatch } from "react-hook-form"
import { useDebounce, useUpdateEffect } from "react-use"
import { addDays } from "date-fns"

import * as callViewValue from "@/components/CallView/value"
import { useFormValidation } from "@/hooks/formValidation"
import { CallReportType } from "@/hooks/useSendCallReport"
import { contactStorageService } from "@/services/localStorageService"

import { CallReportOption } from "./call-report-options"

export function useCallReportStatelessForm({
  availableCallReportOptions,
  callReportType,
  form,
  onSave,
}: {
  availableCallReportOptions: CallReportOption[]
  callReportType: CallReportType
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  onSave: (data: callViewValue.Decoder.DecoderType) => void
}) {
  const { validDate, validStartDateTime: validateCallScheduling } =
    useFormValidation()

  // persist form state to local storage; text fields are debounced
  const persistToLocalStorage = (isSaved: boolean) => {
    const formState = form.getValues()

    const value = {
      ...formState,
      updated_at: isSaved ? new Date() : undefined,
    }

    contactStorageService.setCallReport({ value, isSaved })
  }

  // Reset the form when user enters or leaves the "skip-mode" state
  useUpdateEffect(() => {
    form.reset()
  }, [callReportType])

  usePersistForm(form, () => persistToLocalStorage(false))

  const { control, handleSubmit, resetField, watch } = form

  const selectedStatus = useWatch({
    control,
    name: "status",
  })

  const selectedReason = useWatch({
    control,
    name: "reason",
  })

  const options = {
    status: availableCallReportOptions.map((item) => item.status),
    reason:
      availableCallReportOptions.find(
        (item) => item.status.value === selectedStatus,
      )?.reasons ?? [],
  }

  const isDatePickerEnabled = shouldScheduleCall(
    selectedReason || selectedStatus,
  )

  const clearDateField = () =>
    resetField("scheduledCallDate", { defaultValue: null })

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // Reset reason when status changes
      if (name === "status" && type === "change") {
        resetField("reason", { defaultValue: "" })
      }

      // Reset date value when the field is not available
      const shouldClearDateField =
        !shouldScheduleCall(value.reason || value.status) &&
        Boolean(value.scheduledCallDate)
      if (shouldClearDateField) {
        clearDateField()
      }
    })

    return () => subscription.unsubscribe()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitValidForm = handleSubmit((data) => {
    persistToLocalStorage(true)

    onSave(data)
  })

  const isDisabled = callReportType === "disabled"

  const dateConstraints = {
    minDate: new Date(),
    maxDate: addDays(new Date(), 6),
  }

  return {
    options,
    clearDateField,
    dateConstraints,
    handleSubmit: handleSubmitValidForm,
    validDate,
    isDatePickerEnabled,
    isDisabled,
    validateCallScheduling,
    selectedStatus,
  }
}

function shouldScheduleCall(reason?: string) {
  return reason === "日時を指定して再架電"
}

export function usePersistForm(
  form: UseFormReturn<callViewValue.Decoder.DecoderType>,
  persistFormState: (state: callViewValue.Decoder.DecoderType) => void,
) {
  const [status, reason, scheduledCallDate] = form.watch([
    "status",
    "reason",
    "scheduledCallDate",
  ])
  const memoText = form.watch("memo")

  useEffect(() => {
    persistFormState(form.getValues())
  }, [status, reason, scheduledCallDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useDebounce(
    () => {
      persistFormState(form.getValues())
    },
    500,
    [memoText],
  )
}
