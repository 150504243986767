import { Translations } from "./en"

export const ja: Translations = {
  translation: {
    global: {
      // TODO: Update this translation
      emptySubtitle: "有効なデータが見つかりませんでした。",
      loadingProfile: "プロフィールを読み込み中",
      initializingProfileTitle: "お待ちください。",
      initializingProfileSubtitle: "お使いの情報を収集しています。",
      slowConnectionTitle: "読み込み中",
      slowConnectionSubtitle: "お待ちください, 接続が遅いです。",
      loadingResetView: "通話画面をリセット中",
      errors: {
        unauthorized: "このページにアクセスする権限がありません。",
      },
      peopleCount: "{{count}} 人",
      button: {
        add: "追加",
        edit: "編集",
        submit: "Submit",
        save: "保存する",
        update: "更新する",
        login: "ログインする",
        logout: "ログアウト",
        skip: "スキップする",
        accept: "電話する",
        acceptInbound: "電話を受ける",
        endCall: "通話を終了",
        hold: "保留",
        resume: "保留解除",
        mute: "ミュート",
        unmute: "ミュート解除",
        endTask: "確認して次の方へ",
        call: "電話する",
        addStatus: "ステータスを追加する",
        cancel: "キャンセル",
        loading: "読み込み中",
        savingReport: "レポートを保存中",
        resetPassword: "新しいパスワードを申請する",
        refreshPage: "ページを更新する",
        showMore: "詳しく表示",
        showLess: "閉じる",
        goBack: "戻る",
        close: "閉じる",
        download: "ダウンロード",
        upload: "ファイルをアップロード",
        moreActions: "その他",
        apply: "適用",
        wontCall: "電話しない",
        confirm: "確認",
        confirmAndReturn: "確認して学生詳細に戻る",
        clickToCopy: "クリックしてコピー",
        copied: "コピー完了!",
      },
      search: {
        action: "検索",
        noItemsFound: "検索結果なし",
        selectedValues: "{{count}} 件選択",
      },
      modals: {
        skipTask: {
          title: "本当にスキップしますか？",
          description:
            "通話をスキップすると、この学生は明日の通話リストの後ろに移動します。",
        },
        abandonCallReport: {
          title: "ステータスの更新を中止してもよろしいですか？",
          description: "すべての変更が失われます",
        },
        goOffline: {
          title: "待機中の通話があります",
          description: "この通話をスキップしてオフラインにしますか？",
          callError: "通話中はオフラインにできません。",
        },
        redirect: {
          title: "このページを表示するにはオフラインにする必要があります。",
          description:
            "このページを表示するには、まずオフラインにしてください。",
        },
        microphoneNotShared: {
          title: "マイクを接続してください",
          win: {
            subtitle: "Windowsのみ",
            button: "Windowsのみ",
            text: [
              "タスクバーの右下にあるスピーカーアイコンをクリックする。",
              "「音量ミキサーを開く」をクリックする。",
              "利用可能なオーディオ出力デバイスのリストが表示される。Chromeを含むすべてのシステムサウンドに使用したいデバイスを選択する。",
            ],
          },
          mac: {
            subtitle: "Macのみ",
            button: "Macのみ",
            text: [
              "画面の左上にあるAppleメニューをクリックする。",
              "「システム設定」をクリックする。",
              "「サウンド」をクリックする。",
              "「出力」タブをクリックします。リストから希望のオーディオ出力デバイスを選択する。",
            ],
          },
        },
        browserError: {
          title:
            "お使いのブラウザはサポートされていないため、通話をすることができません。",
          text: [
            "現在のブラウザではCalliopeはサポートされていません。",
            "通話をするには、Google Chrome、Microsoft Edge、またはMozilla Firefoxをご使用ください。",
          ],
        },
        manualCallLeaveConfirm: {
          title: "ページを離れて通話を終了しますか？",
          description:
            "ページを離れることで通話は終了されます。この際、学生のステータスや架電予定は、最新のものが保持されます。",
          confirmation: "本当にこのページを離れますか？",
          submit: "ページを離れ通話を終了",
        },
        manualCallFinisher: {
          title: "未完の作業が検出されました",
          description:
            "未完了の作業が検出されましたので、こちらで整理いたします。",
          submit: "完了",
        },
      },
      datepicker: {
        placeholder: "日付を選択",
        rangePlaceholder: "日付範囲を選択",
        timeLabel: "時間",
        today: "本日",
        thisWeek: "今週",
        last7days: "過去7日間",
        thisMonth: "今月",
        last30days: "過去30日間",
      },
    },
    sidebarMenu: {
      callPanel: "通話履歴",
      campaigns: "案件",
      noCallList: "NGリスト",
      performance: "パフォーマンス",
      syncAgents: "スタッフ更新",
      taskRenewal: "タスク更新予定",
    },
    header: {
      // TODO: Review translations
      admin: "管理者",
      agent: "エージェント",
      callCenterManager: "コールセンターマネージャー",
      callCenterManagerAndAgent: "コールセンターマネージャーとエージェント",
      qualityAnalyst: "品質分析者",
      agentSelectOptions: {
        routable: "稼働中",
        offline: "オフライン",
        initializing: "Initializing",
      },
      language: "言語",
      logout: "ログアウト",
    },
    campaigns: {
      list: {
        title: "案件",
        name: "案件名",
        studentCount: "学生数",
        studentsToBeCalled: "本日の架電可能学生数",
        status: "ステータス",
        create: "案件を作成",
      },
      search: {
        activeOnly: "有効な案件のみ表示",
      },
      details: {
        viewList: "案件一覧",
        lastUpdate: "最終編集日",
        currentNumbers: "現在の学生数",
        viewStatuses: "ステータス状況を表示",
        exportCSV: {
          action: "ステータス状況をダウンロード",
          loading: "CSVファイルを準備中",
          success: "CSVファイルが準備されました。",
          download: "ダウンロードCSV",
        },
        importCSV: {
          action: "ステータスを追加",
          errorRow: "{{row}}行目: {{message}}",
          errorRowColumn: "{{row}}行目{{column}}列目: {{message}}",
        },
        students: {
          name: "氏名",
          email: "メール",
          contactDate: "更新日時",
          contactCount: "架電回数",
          currentStatus: "架電結果",
          comment: "コメント",
        },
        search: {
          universities: "全ての大学",
          statuses: "全ての架電結果",
        },
        actions: {
          editComment: {
            label: "操作履歴の編集",
            success: "操作履歴を更新しました",
            error: "操作履歴を更新できませんでした",
          },
        },
      },
      edit: {
        breadcrumb: "案件を編集中",
      },
      create: {
        title: "作成を作成",
        breadcrumb: "作成",
        queuePlaceholder: "キューを選択してください",
        errors: {
          MISSING_OUTBOUND_CALLER_NUMBER_ID:
            "アウトバウンドコールの発信者番号を設定してください。",
        },
      },
      statuses: {
        starting: { label: "開始処理中" },
        active: { label: "アクティブ" },
        stopping: { label: "停止処理中" },
        paused: { label: "停止中" },
        "starting-failure": {
          label: "開始失敗",
          explanation: [
            "案件の開始に失敗しました。",
            "管理者に連絡してください",
          ],
        },
        "stopping-failure": {
          label: "停止失敗",
          explanation: [
            "案件の停止に失敗しました。",
            "管理者に連絡してください",
          ],
        },
      },
      actions: {
        resume: "再開する",
        pause: "停止する",
      },
      dialogs: {
        error: "案件のステータスを変更できませんでした。",
        pause: {
          title: "案件を中止する",
          description:
            "このアクションを確認することで、案件に関連するすべての発信が直ちに中止されます。",
          confirm: "本当にこの案件を中止しますか？",
          success: "案件が中止されました。",
        },
        resume: {
          title: "案件を再開する",
          confirm: [
            "この案件を再開してもよろしいですか？",
            "複数の案件が停止・開始処理中の場合、こちらの処理に時間がかかることがあります。",
            "最新の状況を確認するには更新ボタンをクリックしてください。",
          ],
          success: "案件が再開されました。",
        },
        statusUpdate: {
          addTitle: "ステータスを追加",
          addButton: "追加する",
          successDescription: "新しいステータス無事に追加しました。",
          failedDescription:
            "エラーによりステータスを追加できませんでした。後でもう一度お試しください。",
        },
      },
      filters: {
        title: "リストを絞り込む",
        add: {
          short: "追加",
          long: "フィルタを追加する",
          smartFilters: "スマートフィルタ",
          basicFilters: "絞り込む",
          manualAdditions: "手動追加",
        },
        shortSummary: "{{count}}の{{label}}",
        channels: {
          label: "チャンネルのQ&A",
          short: "チャンネル",
          placeholder: "チャンネルを選択",
          add: "チャンネルを追加",
          remove: "チャンネルを削除",
          operators: {
            and: "AND",
            or: "OR",
            "except-for": "除外",
          },
          apply: "フィルターを適用",
        },
        university: {
          label: "大学",
          selectedFilters: "{{count}}のフィルター",
          list: "大学リスト",
          group: "大学グループ",
          include: "含む",
          exclude: "除外",
        },
        graduation_year: {
          label: "卒業年度",
        },
        major: {
          label: "学部カテゴリー",
        },
        selected_phone_numbers: {
          label: "電話番号",
          header: "学生を手動で追加",
          explanation:
            "電話番号を入力して、学生を案件に手動追加することができます。",
          details: [
            "複数の学生を一括で追加したい場合、電話番号を改行で区切ってご入力ください。",
            "電話番号は半角英数字でご入力ください (ハイフン有り/無しどちらでも可)。",
          ],
          submit: "プレビューに進む",
          errors: {
            DUPLICATE: "重複する電話番号",
            INVALID_PHONE_NUMBER: "無効な電話番号",
            NOT_FOUND: "電話番号が見つかりません",
          },
          preview: {
            header: "入力内容のプレビュー",
            noStudentsFound: "No students found!",
            found: "人の学生が案件に追加されます",
            errors: "件のエラーがあります (※エラー項目は案件に反映されません)",
            onlyErrors: "エラーのみ表示",
          },
        },
        department_group: {
          label: "学部",
        },
        gender: {
          label: "性別",
        },
        selected: "{{count}}の{{propertyName}}を絞込み中",
        question: {
          placeholder: "質問を選択",
        },
        answer: {
          placeholder: "答えを選択",
        },
        noFilterSelected: "特になし",
        success: "案件が保存されました。",
      },
      priorityFilters: {
        title: "リストの優先順位",
        warningNoFilters: "使用不可,フィルターを追加してください。",
      },
      preview: {
        table: {
          title: "現在{{count}}人に絞り込み中",
          noResults: "絞り込み結果なし",
          lastName: "姓",
          firstName: "名",
          phoneNumber: "携帯番号",
          channels: "チャンネルのQ&A",
          university: "大学",
          department: "学部",
          major: "学部カテゴリー",
          graduationYear: "卒業年度",
          gender: "性別",
        },
        emptyState: "フィルタを追加して生徒リストを取得",
      },
    },
    entries: {
      title: "エントリー管理",
      upload: {
        title: "複数結果を追加",
        panelTitle: "複数の結果を追加するには、以下の手順に従ってください：",
        instruction1: "以下のファイルをダウンロードする：",
        instruction2: "次の手順に従って、追加するファイルを編集する：",
        stepText1: "学生の電話番号を入力   ",
        stepText2: "以下からのエントリの結果を入力:",
        instruction3: "編集したファイルを入力欄に追加する：",
        noFilePlaceholder: "ファイルがありません",
        uploadButtonText: "ファイルを添付",
        cancelButtonText: "キャンセルして一覧に戻る",
        confirmButtonText: "ファイルを確認",
        modal: {
          errorTitle: "エラーが見つかりました ({{count}})",
          successTitle: "エラー無し！",
          successDescription:
            "{{count}}件のエントリー結果が更新されました。学生情報の更新を確認するには、学生情報リストに進んでください。",
        },
      },
    },
    performance: {
      export: {
        csvDownload: "CSVダウンロード",
        confirmTitle: "ダウンロードする前に確認すること",
        dateSelectText: "ダウンロード可能な日付",
        campaignConfirmText: "選択されたキャンペーン",
        allCampaigns: "全てのキャンペーンをダウンロード",
        note: "注意",
        noteText:
          "ダウンロードされるキャンペーンデータには以下のものが含まれます：",
        downloadRequestButton: "ダウンロード準備する",
        downloadButton: "ダウンロードする",
        loadingTitle: "ダウンロード準備中",
        loadingDescription:
          "CVSをダウンロードできるように準備しますので、しばらくお待ちください。",
        successTitle: "CSVダウンロード可能",
        successDescription:
          "下のボタンをクリックしてCSVをダウンロードしてください。",
      },
      tabs: {
        campaign: "案件",
        staff: "スタッフ",
        personal: "個人",
      },
      placeholder: {
        search: "検索",
      },
      table: {
        campaignName: "案件名",
        agentName: "氏名",
        deletedUser: "[削除されたユーザー]",
      },
      detail: {
        agentTitle: "スタッフのパフォーマンス",
        campaignTitle: "案件詳細",
        callsPerHour: "時間当たりのコール数",
        creditForEntryConfirmedCVR: "エントリー確定 / 代理エントリー確定",
        totalProcessingTime: "総処理時間",
      },
      error: {
        invalidFormat: "データの表示に問題がありました",
      },
    },
    syncAgents: {
      description:
        "Amazon connectのデータベースと同期してスタッフ情報を更新するには、下のボタンをクリックしてください。",
      buttonText: "スタッフ更新",
      syncing: "更新中",
      success: "スタッフが更新されました。",
      error: "更新できませんでした。",
      latestUpdateAt: "最終更新日: {{date}}",
    },
    dashboard: {
      title: "単位詳細",
      tabs: {
        performance: "パフォーマンス単位",
        statuses: "ステータス単位",
      },
      csv: {
        exportAction: "エクスポートCSV",
        exportLoading: "CSVファイルを準備中",
        downloadAction: "ダウンロードCSV",
      },
      selectOptions: {
        values: {
          allStaff: "全員スタッフ単位",
        },
        labels: {
          staffPerformance: "スタッフ単位",
          campaignPerformance: "案件単位",
        },
        searchPlaceholder: "検索",
        selectPlaceholder: "選択する",
      },
      table: {
        header: {
          lastName: "姓",
          firstName: "名",
          numberOfCalls: "コール数",
          connectedCalls: "通電数",
          entries: "エントリー数",
          actualEntries: "実エントリー数",
          login: "ログイン",
          ringTime: "呼び出し時間",
          talkTime: "通話時間",
          operation: "処理時間",
          grandTotal: "総計",
          totalNumber: "総数",
          totalTime: "総時間",
          hourly: "時間当り",
          average: "平均",
          rate: "率",
        },
        values: {
          hours: "時間",
        },
      },
    },
    ids: {
      taskId: "タスクID:",
      voiceId: "音声ID:",
      uuid: "UUID:",
    },
    callPanel: {
      title: "学生情報",
      loadingSubtitle: "次の架電を準備中。",
      incomingCall: "電話着信",
      emptyQueue: {
        subtitle: "お疲れさま！",
        cardTitle: "本日の架電業務は終わりました。",
        cardText:
          "パソコンの電源を切る前に、自身ステータスをオフラインにしてください。",
      },
      resetState: {
        title: "予期せぬエラーが発生しました",
        subtitle: "続けるにはページを更新してください。",
        queueRefill: {
          title: "追加の架電をリクエスト中にエラーが発生しました",
          subtitle:
            "キューに追加の連絡先を補充している際に問題が発生しました。ページを更新するにはボタンをクリックしてください。 ",
          rateLimitSubtitle:
            "連絡先を追加してキューを補充している際にレート制限を超えました。しばらくお待ちいただき、利用可能になったらページを更新するためにボタンをクリックしてください。",
        },
      },
      requestWork: {
        title: "次の架電を準備中",
        subtitle: [
          "現在新しい架電を読み込んでいます。",
          "この処理には数十秒かかることがあります、そのままお待ち下さい。",
        ],
      },
      status: {
        connecting: "接続中",
        error: "接続エラー",
        // TODO: Improve these translations
        missedCall: "不在着信",
        missedCallAgentState: "通話接続の問題：ネットワークまたはマイク",
        missedTask: "タスクを逃した",
        rejectedCall: "通話拒否",
        connected: "接続済み",
        acw: "通話結果を入力中",
        skipped: "スキップしました",
      },
      report: {
        title: "ステータスを入力中",
      },
      phoneNumber: "電話番号",
      reason: {
        invalidQueueARN:
          "システムの設定エラーのため、この学生に架電することはできません。",
        disabledCampaign:
          "案件が停止中のため、この学生に電話をかけることは出来ません。",
      },
      idle: {
        heading: "一定時間操作がなかったため稼働を停止しました",
        body: [
          "{{minutes}}分以上操作がなかったため稼働を一時的に停止しました。この間に経過した時間は処理時間としてカウントされません。",
          "業務に戻るには「稼働を再開する」をクリックしてください。",
        ],
        button: "稼働を再開する",
      },
      callErrorCard: {
        networkError: {
          title: "ネットワークエラー",
          description: [
            "ネットワークエラーが発生しました。",
            "しばらくしても直らない場合以下の解決策をお試しください：",
            ["ページの更新", "ブラウザの再起動", "PCの再起動"],
          ],
        },
        browserError: {
          title: "ブラウザがサポートされていません",
          description: [
            "現在のブラウザではCalliopeはサポートされていません。",
            "通話をするには、Google Chrome、Microsoft Edge、またはMozilla Firefoxをご使用ください。",
          ],
        },
        micPermissionError: {
          title: "マイクの接続エラー",
          description: ["架電を続けるにはマイクをPCに接続してください。"],
          button: "設定方法を確認する",
        },
        unexpectedError: {
          title: "不明なエラー",
          description: [
            "不明なエラーが発生しました。",
            "しばらくしても直らない場合以下の解決策をお試しください：",
            ["ページの更新", "ブラウザの再起動", "PCの再起動"],
          ],
        },
        missedCallNetworkError: {
          title: "接続エラー",
          description: [
            "接続エラーが発生したため通話を終了しました。",
            "通話結果を入力し保存するか、「前の画面に戻る」から1つ前の画面に戻り再架電してください。",
          ],
        },
      },
    },
    caseHistory: {
      entorId: "エンターID",
      nameFurigana: "ふりがな",
      university: "大学",
      department: "学部",
      graduation: "卒業年",
      question: "学生時代に力をいれたこと",
      agent: "エージェント",
      campaign: "案件",
      callStatus: "通話状況",
      contactHistory: "全案件の通話履歴",
      email: "E-メール",
      hiddenStatus: {
        scheduled: "未架電",
        renewed: "再架電",
        recovery: "リカバリー",
      },
      tabs: {
        history: "履歴",
        numberOfItems: "回数{{count}}",
        detailedInformation: "詳細情報",
      },
      activityFeed: {
        staff: "スタッフ名",
        comments: "メモ",
        lastUpdate: "更新日時",
        nextCall: "次回通話予定",
        status: "ステータス",
        callType: {
          label: "操作タイプ",
          values: {
            noCall: "通話なしの追加",
            outbound: "アウトバウンド通話",
            inbound: "インバウンド通話",
          },
        },
      },
    },
    sidePanelMetrics: {
      open: "本日の架電状況",
      description: [
        "データは現在の担当案件のみをもとに表示されています。",
        "現在あなたが担当している案件のリストはこちらです：",
      ],
      title: ["本日の", "架電状況"],
      callsPerHour: "コール数/h",
      connectedCalls: {
        perHour: "通電数/h",
        rate: "通電率",
      },
      conversions: {
        total: "CV数",
        rate: "CVR",
      },
      entries: {
        total: "見込みCV数",
        rate: "見込みCVR",
      },
    },
    callResult: {
      title: "通話結果",
      status: "ステータス",
      reason: "理由",
      scheduleCall: "再架電を予約する",
      memo: "メモ",
      placeholder: "テキスト入力",
      success: "通話結果を送信しました。",
      pcrTitle: "ステータスの更新",
      groups: {
        out: "アウト",
        callAgain: "再架電",
      },
    },
    callHistory: {
      heading: "通話履歴",
      searchPlaceholder: "氏名で検索",
    },
    login: {
      emailLabel: "メールアドレス",
      emailPlaceholder: "name@domain.com",
      passwordLabel: "パスワード",
      passwordPlaceholder: "パスワード入力",
      subtitle: "アカウントにログインする",
      help: "ヘルプが必要ですか？",
      contactManager: "マネージャーまでお問い合わせください。",
      forgotPassword: "パスワードをお忘れですか？",
      loadingText: "情報の確認中",
    },
    resetPassword: {
      request: {
        title: "パスワードをお忘れですか？",
        subtitle:
          "以下のフォームにメールアドレスを入力してください。パスワードをリセットするためのリンクがメールアドレスに送信されます。",
        loadingText: "情報の確認中",
        success:
          "パスワードをリセットするための手順が記載されたメールが送信されました。",
        tryAgain: "メールが届かない場合",
      },
      confirm: {
        title: "新しいパスワードを設定",
        subtitle: "以下のルールに従ってパスワードを設定してください。",
        bulletPoints: [
          "パスワードは8文字以上",
          "アルファベットで大文字と小文字使用すること",
          "スペースは使用しないこと",
          "以下の文字の少なくとも1種類を含む",
        ],
        subBulletPoints: ["数字", "特殊文字"],
        confirmPasswordLabel: "パスワード (確認用)",
        loadingText: "情報の確認中",
        success: "パスワードが正常にリセットされました。",
      },
    },
    updateCheckerNotification: {
      title: "新しいバージョンが利用可能です。",
      description: "ページをリロードするには更新ボタンをクリックしてください。",
      ignore: "後で",
      update: "今すぐ更新",
    },
    noCallList: {
      columns: {
        updatedAt: "更新日時",
      },
      importCSV: {
        action: "CSVで一括更新",
        intro: "NGリストを一括で更新するには、以下の手順に従ってください：",
        step1: "以下のファイルをダウンロードする：",
        step2: "追加するファイルを編集する：",
        details: [
          "学生の電話番号、Emailを入力",
          "NGリストに追加したい学生のNo CallカラムにTRUEと入力",
          "NGリストから削除したい学生のNo CallカラムにFALSEと入力",
        ],
        step3: "結果を確認する",
        explanations:
          "選択を確定すると、システムはファイルにエラーがないかチェックし、NGリストを一括更新していきます。",
        globalError: "ファイル{{filename}}のアップロードに失敗しました",
        errors: {
          TOO_MANY_ROWS: "合計行数が{{max}}を超えています。",
          INVALID_CSV: "無効なCSVファイルです。",
          PHONE_NUMBER_INVALID: "無効な電話番号 {{phoneNumber}}",
          PHONE_NUMBER_NOT_FOUND: "電話番号が見つかりません {{phoneNumber}}",
          PHONE_NUMBER_DUPLICATE: "重複する電話番号 {{phoneNumber}}",
          EMAIL_INVALID: "無効なメールアドレス {{email}}",
          EMAIL_NOT_FOUND: "メールアドレスが見つかりません {{email}}",
        },
      },
    },
    taskRenewal: {
      heading: "タスク更新予定",
      days: "〜{{days}}日",
      oneDay: "〜24時間",
      tasks: "{{tasks}}件",
    },
    errors: {
      errorPage: {
        goBack: "calliope.co.jpに戻る",
        notFoundError: "ページが見つかりませんでした",
        notFoundDescription: "お探しのページは見つかりませんでした。",
        unexpectedError: "予期せぬエラーが発生しました。",
        unexpectedErrorDescription: "ログアウトして再度ログインしてください。",
      },
      form: {
        required: "{{fieldName}}を入力してください。",
        wrongDate: "正しい日程を選択してください",
        unknown: "この{{fieldName}}は使用できません。",
        validStartDateTime:
          "{{fieldName}}は未来の日時を設定する必要があります。",
      },
      login: {
        passwordFormat: "パスワードの形式が正しくありません。",
        emailFormat: "メールアドレスの形式が正しくありません。",
        userNotFoundOrUnauthorized:
          "ユーザー名またはパスワードが正しくありません。",
        unknownError: "エラーが発生しました!",
        passwordAttemptsExceeded: "パスワード試行回数が超過しました。",
      },
      resetPassword: {
        unknownError: "パスワードをリセットできませんでした。",
        LimitExceededException: "パスワードリセットの試行回数を超過しました。",
        UserNotFoundException: "メールが見つかりません",
        mismatchingPassword: "パスワードが一致しません。",
        getNewCode:
          "エラーが発生しました。新しいコードをリクエストしてください。",
      },
      callPanel: {
        nextContactError: "次の通話に切り替えることができませんでした。",
        callError: "通話を開始できませんでした。",
        answerCallError: "通話に応答できませんでした。",
        skipCallError: "通話をスキップできませんでした。",
        endCallError: "通話を終了できませんでした。",
        resumeCallError: "通話を再開できませんでした。",
        holdCallError: "通話を保留できませんでした。",
        muteCallError: "通話をミュートできませんでした。",
        unmuteCallError: "通話のミュートを解除できませんでした。",
        statusUpdateError: "ステータスを追加できませんでした。",
      },
      callResult: {
        submitError: "通話結果を送信できませんでした。",
      },
    },
  },
} as const
