import { Dispatch, FunctionComponent, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"
import { Button } from "@/components/ui/button"
import { type AgentDisplayMode } from "@/pages/CustomConnectPanel/hook"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useResetCallViewHook } from "./hook"

const styles = {
  container: "flex flex-col items-center w-full h-full gap-y-6 px-8 pb-8 pt-36",
  icon: "text-danger-200",
  textContainer: "flex flex-col gap-y-2 text-neutral-700",
  title: "font-h1-bold",
  subtitle: "font-body-large-regular",
}

interface Props {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  setDisplayMode: Dispatch<SetStateAction<AgentDisplayMode>>
}

const ResetCallView: FunctionComponent<Props> = ({
  currentCase,
  setDisplayMode,
}) => {
  const { t } = useTranslation()
  const { isLoading, onResetCallView } = useResetCallViewHook({
    currentCase,
    setDisplayMode,
  })

  return (
    <div className={styles.container} data-unmask>
      <Icon name="alert" size="md" className={styles.icon} />
      <div className={styles.textContainer}>
        <h1 className={styles.title}>{t("callPanel.resetState.title")}</h1>
        <p className={styles.subtitle}>{t("callPanel.resetState.subtitle")}</p>
      </div>
      <Button
        type="button"
        onClick={onResetCallView}
        isLoading={isLoading}
        isDisabled={isLoading}
        loadingMessage={t("global.loadingResetView")}
      >
        {t("global.button.refreshPage")}
      </Button>
    </div>
  )
}

export default ResetCallView
