/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useBeforeUnload } from "react-router-dom"
import { useAtomValue } from "jotai"

import { getContactIdFromAgent } from "@/helpers/agent"
import { agentAtom } from "@/helpers/atoms"
import { isNull } from "@/helpers/typeguards"
import {
  ProcessingTimeData,
  processingTimeStorage,
} from "@/services/localStorageService/processing-time.service"

export function useTrackProcessingTime(contactId: string) {
  const { update } = useProcessingTimeEvents(contactId)

  useEffect(() => {
    update({ is_paused: false }) // when mounting and when contactId changes: timer is running

    return () => update({ is_paused: true }) // when unmounting: timer is paused
  }, [contactId])

  useBeforeUnload(() => update({ is_paused: true })) // when reloading the browser tab
}

export function useProcessingTimeEvents(contactId: string) {
  const { getData, setData } = useProcessingTimeState()

  const update = (changes: Pick<ProcessingTimeData, "is_paused">) => {
    setData((current) => {
      return current?.contactId === contactId
        ? {
            ...current,
            prev_duration: computeProcessingTime(current),
            ...changes,
          }
        : { contactId, prev_duration: 0, is_paused: false }
    })
  }

  const pause = () => {
    setData((current) => {
      return current
        ? {
            ...current,
            prev_duration: computeProcessingTime(current),
            is_paused: true,
          }
        : { contactId, prev_duration: 0, is_paused: true }
    })
  }

  const resume = () => {
    setData((current) => {
      return current
        ? { ...current, is_paused: false }
        : { contactId, prev_duration: 0, is_paused: false }
    })
  }

  const getProcessingTime = () => {
    const data = getData()

    return isNull(data) ? 0 : computeProcessingTime(data)
  }

  return {
    update,
    pause,
    resume,
    isPaused: getData()?.is_paused ?? false,
    getProcessingTime,
  }
}

function useProcessingTimeState() {
  const getData = () => {
    return processingTimeStorage.getItem()
  }

  const setData = (
    updaterFn: (
      current: ProcessingTimeData | null,
    ) => Omit<ProcessingTimeData, "updated_at_timestamp">,
  ) => {
    const currentData = processingTimeStorage.getItem()
    const data = updaterFn(currentData)

    processingTimeStorage.setItem({ ...data, updated_at_timestamp: new Date() })
  }

  const getProcessingTime = () => {
    const data = processingTimeStorage.getItem()

    return isNull(data) ? 0 : computeProcessingTime(data)
  }

  return {
    getData,
    getProcessingTime,
    setData,
  }
}

/** To be called by `useSendCallReport`, where we don't have the contactId context */
export function useGetProcessingTime() {
  const agent = useAtomValue(agentAtom)
  const contactIdForProcessingTime = getContactIdFromAgent(agent)
  const { getData } = useProcessingTimeState()

  const getProcessingTime = () => {
    if (!contactIdForProcessingTime) return 0
    const data = getData()
    if (data?.contactId !== contactIdForProcessingTime) {
      console.warn(
        `Processing time KPI is unavailable because processing time data is not matching the current contact ID: ${contactIdForProcessingTime}.`,
        {
          currentContactId: contactIdForProcessingTime,
          storedTimeData: data,
        },
      )

      return 0
    }

    return computeProcessingTime(data)
  }

  return getProcessingTime
}

/**
 * Compute a processing time value based on the timestamp and the previous duration
 * spent processing the contact; This value is posted to the call report API
 */
function computeProcessingTime({
  is_paused,
  prev_duration,
  updated_at_timestamp,
}: Pick<
  ProcessingTimeData,
  "is_paused" | "prev_duration" | "updated_at_timestamp"
>) {
  const now = new Date()

  const diffInSeconds = is_paused
    ? 0
    : (now.getTime() - updated_at_timestamp.getTime()) / 1000

  const processingTime = diffInSeconds + prev_duration

  return parseInt(processingTime.toFixed(0))
}
