import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"
import Modal from "@/components/Modal"
import { Button } from "@/components/ui/button"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useGoOfflineHook } from "./hook"

const styles = {
  childrenContainer: "px-4 pb-6 flex gap-x-4 items-start",
  modalFooter: "bg-neutral-200 px-4 py-2 gap-x-4 flex justify-end items-center",
  textContainer:
    "flex flex-col gap-y-4 font-body-medium-regular text-neutral-700 grow",
  heading: "font-h3-bold",
  iconContainer: "text-danger-300 bg-danger-100 rounded-full p-1",
}

interface Props {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
}

const GoOfflineWithContactModal: FunctionComponent<Props> = ({
  currentCase,
}) => {
  const { closeModal, goOffline, isLoading } = useGoOfflineHook(currentCase)
  const { t } = useTranslation()

  return (
    <Modal>
      <div data-unmask>
        <div className={styles.childrenContainer}>
          <div className={styles.iconContainer}>
            <Icon name="alert" size="sm" />
          </div>
          <div className={styles.textContainer}>
            <h3 className={styles.heading}>
              {t("global.modals.goOffline.title")}
            </h3>
            <p>{t("global.modals.goOffline.description")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <Button
            type="button"
            variant="outline"
            onClick={closeModal}
            isDisabled={isLoading}
          >
            {t("global.button.cancel")}
          </Button>
          <Button
            type="button"
            onClick={goOffline}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            {t("global.button.skip")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default GoOfflineWithContactModal
