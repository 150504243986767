import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useCleanUpManualCalling } from "@/components/CallPanel/ACWButtons/hook"
import { SingleClickButton } from "@/components/SingleClickButton"
import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { CallReportError } from "@/helpers/error"
import { Timer } from "@/hooks/callDurationTimer"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useContactDataHook } from "@/hooks/contactData"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { useSendCallReport } from "@/hooks/useSendCallReport"

import { CaseWithActivityFeed } from "../student-details-api"

export default function LeavingConfirmation({
  currentCase,
  onClose,
  timer,
}: {
  currentCase: CaseWithActivityFeed | null
  onClose: (val: boolean) => void
  timer: Timer | null
}) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const log = useCallPanelLogger()
  const { closeContact, hangUpPhoneCall } = useConnectActionsHook()
  const cleanup = useCleanUpManualCalling()
  const { isVoiceContact, voiceContactId } = useContactDataHook(currentCase)
  const sendCallReportMutation = useSendCallReport({
    currentCase,
    timer,
  })

  const handleNavigate = async () => {
    try {
      setIsLoading(true)
      if (voiceContactId) {
        await hangUpPhoneCall()
      }
      await sendCallReportMutation.mutateAsync({
        callReportType: "manual-leaving-view",
      })
      cleanup()
      if (isVoiceContact && voiceContactId) {
        await closeContact()
      }
    } catch (err) {
      if (err instanceof CallReportError) {
        log.error(err, { callReportData: err.callReportData })
      } else {
        log.error(err)
      }
    } finally {
      setIsLoading(false)
      onClose(true)
    }
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {t("global.modals.manualCallLeaveConfirm.title")}
        </DialogTitle>
      </DialogHeader>
      <DialogBody>
        <p>{t("global.modals.manualCallLeaveConfirm.description")}</p>
        <p className="mt-2 font-bold">
          {t("global.modals.manualCallLeaveConfirm.confirmation")}
        </p>
      </DialogBody>
      <DialogFooter>
        <Button variant="outline" onClick={() => onClose(false)}>
          {t("global.button.cancel")}
        </Button>
        <SingleClickButton isLoading={isLoading} onClick={handleNavigate}>
          {t("global.modals.manualCallLeaveConfirm.submit")}
        </SingleClickButton>
      </DialogFooter>
    </DialogContent>
  )
}
