import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useForm, UseFormReturn } from "react-hook-form"
import { useAtomValue } from "jotai"

import * as callViewValue from "@/components/CallView/value"
import * as layoutValue from "@/components/layout/value"
import { agentAtom, agentProfileAtom } from "@/helpers/atoms"
import { SentryLogger } from "@/helpers/sentryLogger"
import { isNullish } from "@/helpers/typeguards"
import { formatStringValue as formatValue } from "@/helpers/valueFormat"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"
import { contactStorageService } from "@/services/localStorageService"

interface CallViewHookType {
  contactIdForProcessingTime: string | undefined
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  formattedTaskId: string
  formattedVoiceId: string
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  setHistoryItem: Dispatch<
    SetStateAction<connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null>
  >
  studentId: string
}

const log = new SentryLogger()

const getStoredHistoryEntry = ({
  agent,
  agentProfile,
  currentCase,
  rawCallReport,
}: {
  agent: connect.Agent | null
  agentProfile: layoutValue.Decoder.ConnectUserInformation | null
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  rawCallReport: unknown
}): connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null => {
  try {
    const isInboundCall =
      agent?.getContacts(connect.ContactType.VOICE)[0]?.isInbound() ?? false

    const firstName = agentProfile?.identity_info.first_name ?? ""
    const lastName = agentProfile?.identity_info.last_name ?? ""
    const email = agentProfile?.email ?? ""

    // get the stored call report from local storage that was saved by the agent
    const storedCallReport =
      callViewValue.Decoder.schema.safeParse(rawCallReport)

    if (!storedCallReport.success) {
      return null
    }

    if (!storedCallReport.data.status) {
      return null
    }

    const formattedActivityEntry = connectPanelValue.ActivityFeedEntry.encode(
      storedCallReport.data,
      { firstName, lastName, email },
      currentCase?.case.campaign_id ?? "",
      isInboundCall ? "inbound" : "outbound",
    )

    return formattedActivityEntry
  } catch (err) {
    log.error(err, { agent: agent?.toSnapshot() })

    return null
  }
}

const useCallViewHook = ({
  currentCase,
  studentProfile,
}: {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  studentProfile: connectPanelValue.Decoder.StudentProfile | null
}): CallViewHookType => {
  const agent = useAtomValue(agentAtom)
  const agentProfile = useAtomValue(agentProfileAtom)

  const tasks = agent?.getContacts(connect.ContactType.TASK)
  const voiceContacts = agent?.getContacts(connect.ContactType.VOICE)
  const rawCallReport = contactStorageService.getCallReport(true)

  const formattedActivityEntry = useMemo(() => {
    return getStoredHistoryEntry({
      agent,
      agentProfile,
      currentCase,
      rawCallReport,
    })
  }, [agent, agentProfile, currentCase, rawCallReport])

  const [historyItem, setHistoryItem] =
    useState<connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null>(
      formattedActivityEntry,
    )

  const form = useACWForm()

  const studentId = formatValue(
    currentCase?.case.student_id ?? studentProfile?.student_id,
  )

  const taskId = useMemo(() => {
    if (isNullish(tasks) || !tasks.length) {
      return undefined
    }

    return tasks[0].getContactId()
  }, [tasks])

  const voiceId = useMemo(() => {
    if (isNullish(voiceContacts) || !voiceContacts.length) {
      return undefined
    }

    return voiceContacts[0].getContactId()
  }, [voiceContacts])

  const contactIdForProcessingTime = taskId || voiceId

  return {
    form,
    historyItem,
    setHistoryItem,
    studentId,
    formattedTaskId: formatValue(taskId),
    formattedVoiceId: formatValue(voiceId),
    contactIdForProcessingTime,
  }
}

export function useACWForm() {
  const rawCallReport = contactStorageService.getCallReport(false)
  const defaultValues = callViewValue.Encoder.defaultValues(rawCallReport)

  return useForm<callViewValue.Decoder.DecoderType>({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: false,
    defaultValues: defaultValues,
  })
}

export { useCallViewHook }
