import { useTranslation } from "react-i18next"
import { useSetAtom } from "jotai"

import { modalModeAtom } from "@/helpers/atoms"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useContactDataHook } from "@/hooks/contactData"
import { useToast } from "@/hooks/toastHook"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { useSendCallReport } from "@/hooks/useSendCallReport"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

interface GoOfflineHookType {
  closeModal: VoidFunction
  goOffline: () => Promise<void>
  isLoading: boolean
}

const useGoOfflineHook = (
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null,
): GoOfflineHookType => {
  const setModalMode = useSetAtom(modalModeAtom)

  const log = useCallPanelLogger()
  const { error: toastError } = useToast()
  const { t } = useTranslation()

  const { closeContact, endTask, toggleAgentStatus } = useConnectActionsHook()

  const { isTask, isVoiceCallWithError, isVoiceContact } =
    useContactDataHook(currentCase)

  const closeModal = () => setModalMode(null)
  const sendCallReportMutation = useSendCallReport({
    currentCase,
  })

  const handleSendSkipCallReport = async (): Promise<void> => {
    try {
      await sendCallReportMutation.mutateAsync({
        callReportType: "automatic-going-offline",
      })
    } catch (error) {
      log.error(error)
    }
  }

  const stopCurrentTask = async () => {
    try {
      await endTask()
    } catch (error) {
      log.error(error)
    }
  }

  const sendReportAndEndTask = async (): Promise<void> => {
    await handleSendSkipCallReport()
    await stopCurrentTask()
  }

  const goOffline = async (): Promise<void> => {
    try {
      if (isVoiceCallWithError) {
        await closeContact()
        await toggleAgentStatus({
          newStateType: connect.AgentStateType.OFFLINE,
        })
      } else if (isVoiceContact) {
        return toastError(t("global.modals.goOffline.callError"))
      } else if (isTask) {
        await toggleAgentStatus({
          newStateType: connect.AgentStateType.OFFLINE,
          enqueueNextState: true,
        })
        await sendReportAndEndTask()
      }
    } catch (error) {
      log.error(error)
    }

    setModalMode(null)
  }

  return { closeModal, goOffline, isLoading: sendCallReportMutation.isPending }
}

export { useGoOfflineHook }
