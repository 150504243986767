import { Dispatch, FunctionComponent, SetStateAction } from "react"
import { UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"

import * as callViewValue from "@/components/CallView/value"
import { isNull } from "@/helpers/typeguards"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useCallPanelHook } from "../CallPanel/hook"
import { useCallReportOptions } from "./call-report-options"
import { CallReportStatelessForm } from "./call-report-stateless-form"
import { ACWFormStatus, useACWFormHook } from "./hook"

interface Props {
  callPanelState: ReturnType<typeof useCallPanelHook>
  className?: string
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  setHistoryItem: Dispatch<
    SetStateAction<connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null>
  >
}

const CallReportForm: FunctionComponent<Props> = ({
  callPanelState,
  className,
  currentCase,
  form,
  historyItem,
  setHistoryItem,
}) => {
  const { isLoadingAgent, onSave, status } = useACWFormHook({
    setHistoryItem,
    currentCase,
  })
  const { t } = useTranslation()

  const callReportType = getAutomaticCallReportType(callPanelState, status)

  const title = t(
    callReportType === "automatic-pre-call"
      ? "callResult.pcrTitle"
      : "callResult.title",
  )

  const options = useCallReportOptions({
    callReportType,
    latestRepeaterStatus: currentCase?.case.latest_repeater_status ?? null,
  })

  return (
    <CallReportStatelessForm
      className={className}
      form={form}
      isNewItem={isNull(historyItem)}
      isLoadingAgent={isLoadingAgent}
      onSave={onSave}
      callReportType={callReportType}
      title={title}
      availableCallReportOptions={options}
    />
  )
}

const getAutomaticCallReportType = (
  callPanelState: ReturnType<typeof useCallPanelHook>,
  status: ACWFormStatus,
) => {
  const isWontCall =
    callPanelState.displayMode.current === "connected-task" &&
    callPanelState.displayMode.state.callReportType === "automatic-pre-call"

  if (isWontCall) return "automatic-pre-call"
  if (status === "disabled") return "disabled"

  return "attempted-call"
}

export default CallReportForm
