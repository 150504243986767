import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import { CurrentStatusPill } from "@/components/status-pill"
import { CopyIdButton } from "@/components/ui/copy-id-button"
import { formatValue } from "@/helpers/valueFormat"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { CallViewHistoryTabs } from "./history-tabs"
import { useContactProfileHook } from "./hook"

const StudentInfoItem: FunctionComponent<{
  labelKey: string
  unmask?: boolean
  value: React.ReactNode
}> = ({ labelKey, unmask = false, value }) => {
  const { t } = useTranslation()

  const styles = {
    card: "flex flex-col gap-y-2 w-1/2 grow",
    label: "w-full font-label-small text-neutral-600",
    value: "w-full font-body-medium-regular text-neutral-700",
  }

  return (
    <div className={styles.card}>
      <div className={styles.label} data-unmask>
        {t(labelKey)}
      </div>
      <div className={styles.value} {...(unmask && { "data-unmask": true })}>
        {value}
      </div>
    </div>
  )
}

const styles = {
  studentInfo: "flex flex-col gap-y-4",
  studentInfoSplitContainer: "flex gap-x-4 w-full",
}

interface Props {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  studentProfile: connectPanelValue.Decoder.StudentProfile | null
}

const ContactProfileView: FunctionComponent<Props> = ({
  currentCase,
  historyItem,
  studentProfile,
}) => {
  const { t } = useTranslation()
  const { contactName, contactNameKana, historyEntries } =
    useContactProfileHook({
      currentCase,
      studentProfile,
      historyItem,
    })

  return (
    <div className="col-span-full rounded lg:col-span-1 lg:col-start-1 lg:row-start-2 lg:row-end-[-1]">
      <div className="flex h-auto flex-col rounded ring-1 ring-neutral-300">
        <div className="flex flex-col gap-y-6 rounded-t border-b border-neutral-300 bg-neutral-100 px-6 pb-6 pt-4">
          <div className="flex flex-col gap-y-1">
            <div
              className="font-label-small flex items-center gap-x-1 text-neutral-500"
              data-unmask
            >
              <span>{t("caseHistory.entorId")}:</span>
              <span>
                {studentProfile?.legacy_id && (
                  <CopyIdButton
                    uuid={studentProfile.legacy_id.toString()}
                    className="text-xs font-bold"
                  />
                )}
              </span>
            </div>
            <h1 className="font-h1-bold text-neutral-700">{contactName}</h1>
          </div>
          <div className={styles.studentInfo}>
            <div className={styles.studentInfoSplitContainer}>
              <StudentInfoItem
                labelKey="caseHistory.nameFurigana"
                value={contactNameKana}
              />
              <StudentInfoItem
                labelKey="callResult.status"
                value={
                  currentCase?.case.current_status ? (
                    <CurrentStatusPill
                      current={currentCase?.case.current_status}
                      latestActionTaken={
                        currentCase?.case.latest_status_action_taken
                      }
                    />
                  ) : (
                    "-"
                  )
                }
                unmask
              />
            </div>
            <div className={styles.studentInfoSplitContainer}>
              <StudentInfoItem
                labelKey="caseHistory.university"
                value={formatValue(studentProfile?.university)}
              />
              <StudentInfoItem
                labelKey="caseHistory.department"
                value={formatValue(studentProfile?.department)}
              />
            </div>
            <div className={styles.studentInfoSplitContainer}>
              <StudentInfoItem
                labelKey="caseHistory.graduation"
                value={formatValue(studentProfile?.graduation_year)}
              />
              <StudentInfoItem
                labelKey="caseHistory.email"
                value={formatValue(studentProfile?.email)}
              />
            </div>
          </div>
        </div>
        <CallViewHistoryTabs
          studentCase={currentCase?.case}
          historyEntries={historyEntries}
        />
      </div>
    </div>
  )
}

export default ContactProfileView
