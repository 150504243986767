import { useTranslation } from "react-i18next"
import { useQueryClient } from "@tanstack/react-query"

import { CallReportStatelessForm } from "@/components/ACWForm/call-report-stateless-form"
import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogBodyError,
  DialogBodySuccess,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { useSendCallReport } from "@/hooks/useSendCallReport"
import { CaseWithActivityFeed } from "@/pages/Campaigns/student-details/student-details-api"

import { useCallReportOptions } from "../ACWForm/call-report-options"
import { useACWForm } from "../CallView/hook"

type Props = {
  close: (confirmed: boolean) => void
}

export function DialogConfirmBackToCallMode({ close }: Props) {
  const { t } = useTranslation()

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{t("global.modals.abandonCallReport.title")}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        {t("global.modals.abandonCallReport.description")}
      </DialogBody>
      <DialogFooter>
        <Button variant="outline" onClick={() => close(false)}>
          {t("global.button.cancel")}
        </Button>
        <Button onClick={() => close(true)}>
          {t("global.button.confirm")}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}

export function StatusUpdateModal({
  onClose,
  studentCase,
}: {
  onClose: (value: boolean) => void
  studentCase: CaseWithActivityFeed
}) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const log = useCallPanelLogger()
  const form = useACWForm()

  const callReportType = "student-details-append-status"

  const sendCallReportMutation = useSendCallReport({
    currentCase: studentCase,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["/case"],
      })
    },
  })

  const handleSubmit = async () => {
    try {
      const formData = form.getValues()
      await sendCallReportMutation.mutateAsync({
        formData,
        callReportType,
      })
    } catch (err) {
      if (err instanceof Error) {
        log.error(err)
      }
    }
  }

  const options = useCallReportOptions({
    callReportType,
    latestRepeaterStatus: studentCase.case.latest_repeater_status ?? null,
  })

  return (
    <DialogContent isPending={sendCallReportMutation.isPending}>
      <DialogHeader>
        <DialogTitle>
          {t("campaigns.dialogs.statusUpdate.addTitle")}
        </DialogTitle>
      </DialogHeader>
      {(sendCallReportMutation.isIdle || sendCallReportMutation.isPending) && (
        <>
          <DialogBody>
            <CallReportStatelessForm
              availableCallReportOptions={options}
              form={form}
              isLoadingAgent={false}
              callReportType={callReportType}
              onSave={handleSubmit}
              isNewItem={false}
              inModal={true}
              title={null}
            />
          </DialogBody>
          <DialogFooter>
            <Button variant="outline" onClick={() => onClose(false)}>
              {t("global.button.cancel")}
            </Button>
            <Button
              disabled={!form.formState.isValid}
              onClick={handleSubmit}
              isLoading={sendCallReportMutation.isPending}
            >
              {t("campaigns.dialogs.statusUpdate.addButton")}
            </Button>
          </DialogFooter>
        </>
      )}
      {sendCallReportMutation.isSuccess && (
        <>
          <DialogBodySuccess>
            {t("campaigns.dialogs.statusUpdate.successDescription")}
          </DialogBodySuccess>
          <DialogFooter>
            <Button variant="outline" onClick={() => onClose(false)}>
              {t("global.button.close")}
            </Button>
          </DialogFooter>
        </>
      )}
      {sendCallReportMutation.isError && (
        <DialogBodyError>
          {t("campaigns.dialogs.statusUpdate.failedDescription")}
        </DialogBodyError>
      )}
    </DialogContent>
  )
}
