import { ChangeEvent, KeyboardEventHandler } from "react"
import { FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Label from "@/components/core/Label"
import { cn } from "@/helpers/classNames"
import { isDefined } from "@/helpers/typeguards"
import { useErrorMessage } from "@/hooks/errorMessage"
import {
  FormInputProps,
  ForwardedRefProps,
  HTMLPassedProps,
  Orientation,
  TargetPosition,
} from "@/types/props"

type InputType = "date" | "email" | "hidden" | "password" | "text"

interface Props<FieldsType extends FieldValues>
  extends HTMLPassedProps<"value">,
    FormInputProps<FieldsType>,
    ForwardedRefProps<HTMLInputElement> {
  defaultValue?: string
  hasError?: boolean
  maxLength?: number
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: KeyboardEventHandler
  title?: string
  type?: InputType
}

const styles = {
  root: (orientation: Orientation, isDisabled: boolean) => {
    return cn([
      "flex w-full",
      orientation === "horizontal"
        ? "flex-row items-baseline gap-x-3"
        : "flex-col gap-y-1",
      isDisabled && "pointer-events-none",
    ])
  },
  inputWrapper: "flex flex-col gap-y-1 w-full",
  targetContainer: "relative flex items-center",
  targetIcon: (targetPosition: TargetPosition, isDisabled: boolean) => {
    return cn([
      "absolute",
      targetPosition === "left" ? "left-2" : "right-3",
      isDisabled ? "text-neutral-400" : "text-neutral-600",
    ])
  },
  input: ({
    hasError,
    hasTarget,
    isDisabled,
    targetPosition,
  }: {
    hasError: boolean
    hasTarget: boolean
    isDisabled: boolean
    targetPosition: TargetPosition
  }) => {
    return cn([
      "font-form-regular w-full cursor-pointer rounded border border-neutral-300 bg-neutral-100 focus:border-neutral-400 focus:outline-none",
      isDisabled
        ? "text-neutral-500 placeholder:text-neutral-400"
        : "text-neutral-700 placeholder:text-neutral-600",
      "min-w-max", // TODO: Change if it affects width of the input component on other pages
      hasTarget
        ? targetPosition === "left"
          ? "py-2 pl-10 pr-4"
          : "py-2 pl-4 pr-10"
        : "px-4 py-2",
      hasError && "border-danger-300",
      isDisabled && "bg-neutral-200 text-neutral-400",
    ])
  },
  error: "text-danger-300 font-paragraph-small-regular",
  label: (labelClassName?: string) =>
    cn(["font-label-medium text-neutral-600", labelClassName]),
}

const Input = <FieldsType extends FieldValues>({
  className,
  clearErrors,
  defaultValue,
  disabled: isDisabled = false,
  errorLabel,
  errors,
  forwardedRef,
  hasError = false,
  inputClassName,
  label,
  labelClassName,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  orientation = "horizontal",
  placeholder,
  readOnly = false,
  required: isRequired = false,
  target,
  targetPosition = "left",
  title,
  type = "text",
  value,
}: Props<FieldsType>) => {
  const { t } = useTranslation()

  const error = useErrorMessage(
    name,
    errors,
    isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name,
  )

  const isSentryIgnoreField = ["email", "password", "tel"].includes(type)

  return (
    <div className={cn([styles.root(orientation, isDisabled), className])}>
      {isDefined(label) && (
        <Label
          label={label}
          labelClassName={styles.label(labelClassName)}
          required={isRequired}
          isDisabled={isDisabled}
        />
      )}
      <div className={styles.inputWrapper}>
        <div className={styles.targetContainer}>
          {isDefined(target) && (
            <div className={styles.targetIcon(targetPosition, isDisabled)}>
              {target}
            </div>
          )}
          <input
            defaultValue={defaultValue}
            disabled={isDisabled}
            type={type}
            className={cn([
              styles.input({
                isDisabled,
                hasError: isDefined(error) || hasError,
                hasTarget: isDefined(target),
                targetPosition,
              }),
              inputClassName,
            ])}
            name={name}
            placeholder={isDefined(placeholder) ? t(placeholder) : undefined}
            onChange={onChange}
            onBlur={() => onBlur && onBlur()}
            onFocus={(event) => {
              clearErrors && clearErrors(name)
              onFocus && onFocus(event)
            }}
            onKeyDown={onKeyDown}
            value={value}
            readOnly={readOnly}
            title={title}
            ref={forwardedRef}
            maxLength={maxLength}
            {...(isSentryIgnoreField && { "data-sentry-ignore": true })}
          />
        </div>
        {isDefined(error) && (
          <div className={styles.error} data-unmask>
            {error}
          </div>
        )}
      </div>
    </div>
  )
}

export default Input
