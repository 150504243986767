import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"
import Modal from "@/components/Modal"
import { Button } from "@/components/ui/button"
import { hasProperty, isValidModalMode } from "@/helpers/typeguards"
import { useRedirectModalHook } from "@/hooks/redirectModal"

const styles = {
  childrenContainer: "px-4 pb-6 flex gap-x-4 items-start",
  modalFooter: "bg-neutral-200 px-4 py-2 gap-x-4 flex justify-end items-center",
  textContainer:
    "flex flex-col gap-y-4 font-body-medium-regular text-neutral-700 grow",
  heading: "font-h3-bold",
  iconContainer: "text-danger-300 bg-danger-100 rounded-full p-1",
}

const NavigateWithContactModal: FunctionComponent = () => {
  const { isLoading, modalMode, onClick } = useRedirectModalHook()
  const { t } = useTranslation()
  const isCloseEnabled =
    hasProperty("mode", isValidModalMode)(modalMode) &&
    modalMode.mode === "navigateWithContact" &&
    modalMode.state.isCloseEnabled

  return (
    <Modal isCloseEnabled={isCloseEnabled}>
      <div data-unmask>
        <div className={styles.childrenContainer}>
          <div className={styles.iconContainer}>
            <Icon name="alert" size="sm" />
          </div>
          <div className={styles.textContainer}>
            <h3 className={styles.heading}>
              {t("global.modals.redirect.title")}
            </h3>
            <p>{t("global.modals.redirect.description")}</p>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <Button
            type="button"
            onClick={onClick}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            {t("global.button.goBack")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default NavigateWithContactModal
