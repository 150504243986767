import { PropsWithChildren } from "react"

import CallView from "@/components/CallView"
import EmptyQueueView from "@/components/EmptyQueueView"
import Loader from "@/components/layout/Loader"
import ResetCallView from "@/components/ResetCallView"
import { useAgentProfile } from "@/hooks/agent-profile"
import { CallDurationProvider } from "@/hooks/callDuration"
import PreparingCallView from "@/pages/CustomConnectPanel/PreparingCallView"

import { CallReportsPage } from "../CallHistory/call-history-page"
import { AgentMetricsPanel } from "./agent-metrics/agent-metrics-panel"
import { useCustomPanelHook } from "./hook"
import { RequestWork } from "./request-work"
import ResetCallViewOnQueueRefillError from "./reset_queue_refill"

const CustomConnectPanel = () => {
  const { displayMode: agentDisplayMode, setDisplayMode } = useCustomPanelHook()

  switch (agentDisplayMode.current) {
    case "offline":
      return (
        <ConnectPanelContainer>
          <CallReportsPage />
        </ConnectPanelContainer>
      )
    case "initializing":
      return <Loader />
    case "available":
      return (
        <ConnectPanelContainer showMetricsPanel>
          <EmptyQueueView />
        </ConnectPanelContainer>
      )

    case "busy":
      return (
        <ConnectPanelContainer showMetricsPanel>
          {agentDisplayMode.state.isLoading ? (
            <PreparingCallView />
          ) : (
            <CallDurationProvider>
              <CallView
                studentProfile={agentDisplayMode.state.studentProfile}
                currentCase={agentDisplayMode.state.currentCase}
              />
            </CallDurationProvider>
          )}
        </ConnectPanelContainer>
      )

    case "reset":
      return (
        <ConnectPanelContainer showMetricsPanel>
          {agentDisplayMode.state.errorContext === "queue-refill-error" ||
          agentDisplayMode.state.errorContext ===
            "queue-refill-rate-limit-error" ? (
            <ResetCallViewOnQueueRefillError
              errorContext={agentDisplayMode.state.errorContext}
            />
          ) : (
            <ResetCallView
              currentCase={agentDisplayMode.state.currentCase}
              setDisplayMode={setDisplayMode}
            />
          )}
        </ConnectPanelContainer>
      )

    case "request-work":
      return (
        <ConnectPanelContainer showMetricsPanel>
          <RequestWork setDisplayMode={setDisplayMode} />
        </ConnectPanelContainer>
      )

    default:
      return null
  }
}

function ConnectPanelContainer({
  children,
  showMetricsPanel,
}: PropsWithChildren<{ showMetricsPanel?: boolean }>) {
  const { agentProfile } = useAgentProfile()

  return (
    <div className="size-full">
      {showMetricsPanel && agentProfile && <AgentMetricsPanel />}
      {children}
    </div>
  )
}

export default CustomConnectPanel
