import { useInterval, useUpdate } from "react-use"

import { DisplayMode } from "@/components/CallPanel/hook"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

import { useProcessingTimeEvents } from "./track-processing-time"

/** Used only for debugging */
export function ProcessingTimeDebugPanel({
  contactId,
  displayMode,
}: {
  contactId: string
  displayMode: DisplayMode
}) {
  const { getProcessingTime, isPaused, pause, resume } =
    useProcessingTimeEvents(contactId)
  const update = useUpdate()
  const duration = getProcessingTime()
  useInterval(update, 1000) // RE-render every second to simulate a running timer

  return (
    <Card className="fixed bottom-4 left-4">
      <CardHeader>
        <CardTitle>Processing Time</CardTitle>
        <CardDescription>
          {displayMode.current} • {contactId}
        </CardDescription>
      </CardHeader>
      <CardContent className="space-x-4">
        <span className="text-xl">{formatDuration(duration)}</span>
        {isPaused ? (
          <Badge variant="danger">Paused</Badge>
        ) : (
          <Badge variant="outline">Running...</Badge>
        )}
      </CardContent>
      <CardFooter className="space-x-4">
        <Button onClick={pause} disabled={isPaused} variant="outline" size="sm">
          Pause
        </Button>
        <Button
          onClick={resume}
          disabled={!isPaused}
          variant="outline"
          size="sm"
        >
          Resume
        </Button>
      </CardFooter>
    </Card>
  )
}

function formatDuration(duration: number) {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
    .toString()
    .padStart(2, "0")
  const seconds = Math.floor(duration % 60)
    .toString()
    .padStart(2, "0")

  return `${hours > 0 ? hours + ":" : ""}${minutes}:${seconds}`
}
