import { FunctionComponent, PropsWithChildren } from "react"
import { Navigate, useLocation } from "react-router-dom"

import AxiosInstanceProvider from "@/components/core/AxiosInstanceProvider"
import { ManualCallProvider } from "@/components/core/ManualCallProvider"
import { CallStatusesProvider } from "@/hooks/call-statuses-context"

import { PageView } from "../Root/analytics"
import { ModalRoot } from "../Root/modal-root"
import { useProtectedRouteHook } from "./hook"

const ProtectedRoute: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { cognitoMode } = useProtectedRouteHook()
  const location = useLocation()

  // TODO: Add a loading spinner for the authenticating state
  if (cognitoMode.current === "AUTHENTICATING") {
    return <div>Authenticating...</div>
  }

  if (cognitoMode.current === "NOT_AUTHENTICATED") {
    return <Navigate to="/login" replace state={{ from: location }} />
  }

  return (
    <AxiosInstanceProvider>
      <CallStatusesProvider>
        <ManualCallProvider>
          {children}
          {/* The modal needs access to the Axios client and the Statuses store */}
          <ModalRoot />
        </ManualCallProvider>
      </CallStatusesProvider>
      <PageView />
    </AxiosInstanceProvider>
  )
}

export default ProtectedRoute
