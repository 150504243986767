import { z } from "zod"

import LocalStorageService from "./localStorageService"

const schema = z.object({
  updated_at_timestamp: z.coerce.date(),
  contactId: z.string().uuid(),
  prev_duration: z.number(),
  is_paused: z.coerce.boolean(),
})

export type ProcessingTimeData = z.infer<typeof schema>

class ProcessingTimeStorage extends LocalStorageService {
  private readonly KEY = "processing_time_data"

  getItem(): ProcessingTimeData | null {
    const parsedValueResponse = schema.safeParse(super.getItem(this.KEY))

    return parsedValueResponse.success ? parsedValueResponse.data : null
  }

  setItem<ProcessingTimeData>(data: ProcessingTimeData) {
    return super.setItem(this.KEY, data)
  }
}

export const processingTimeStorage = new ProcessingTimeStorage()
