import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { ParseKeys } from "i18next"

import { cn } from "@/helpers/classNames"
import { ChildrenProps } from "@/types/props"

export type Variant = "compact" | "primary"

interface Props extends ChildrenProps {
  className?: string
  isDisabled?: boolean
  label: ParseKeys
  labelClassName?: string
  required?: boolean
  unmask?: boolean
  variant?: Variant
}

const styles = {
  labelWrapper: (variant: Variant) =>
    cn([
      "flex flex-row items-center gap-x-2",
      variant === "primary" ? "justify-between" : "justify-start",
    ]),
  label: (isDisabled: boolean) =>
    cn([
      "font-label-medium",
      isDisabled ? "text-neutral-400" : "text-neutral-700",
    ]),
  required: "text-danger-300",
}

const Label: FunctionComponent<Props> = ({
  children,
  className,
  isDisabled = false,
  label,
  labelClassName,
  required = false,
  variant = "primary",
}) => {
  const { t } = useTranslation()

  return (
    <div className={cn([styles.labelWrapper(variant), className])}>
      <label
        className={cn([styles.label(isDisabled), labelClassName])}
        data-unmask
      >
        {t(label)}
        {required && <span className={styles.required}>*</span>}
      </label>
      {children}
    </div>
  )
}

export default Label
