import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"
import ProgressBar from "@/components/ProgressBar"

const styles = {
  container:
    "flex flex-col items-center justify-center w-full h-full gap-y-4 px-34.5",
  iconContainer: "flex flex-col items-center",
  subtitle: "font-h4-bold text-neutral-700",
  loaderContainer: "flex items-center justify-center w-[360px]",
}

const PreparingCallView: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <div
      className={styles.container}
      data-unmask
      data-testid="call-view-loading"
    >
      <div className={styles.iconContainer}>
        <Icon name="folder-empty" size="xl" />
        <h4 className={styles.subtitle}>{t("callPanel.loadingSubtitle")}</h4>
      </div>
      <div className={styles.loaderContainer}>
        <ProgressBar />
      </div>
    </div>
  )
}

export default PreparingCallView
